import { FC } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import c from "classnames";

import Text from "src/ui/typography/Text";

import s from "./style.module.sass";

type Props = {
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: "large";
  className?: string;
  onClick?: () => void;
};

const SubmitButton: FC<Props> = ({
  isLoading = false, isDisabled = false, size, className, children, onClick,
}) => {

  const buttonClass = (() => {
    if (size === undefined) {
      return s.small;
    }
    return s.big;
  })();

  const onInnerClick = () => {
    if (isLoading === false && isDisabled === false && onClick) {
      onClick()
    }
  };

  return (
    <button
      className={c(className, s.button, buttonClass, { [s.buttonDisabled]: isDisabled })}
      onClick={onInnerClick}
      type="button"
    >
      { isLoading ? 
        <LoadingOutlined className={s.loader} />
      :
        <Text className={s.text} size={14} weight={700}>
          { children }
        </Text>
      }
    </button>
  );
};

export default observer(SubmitButton);
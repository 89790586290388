import { FC } from "react";
import { observer } from "mobx-react-lite";
import { DatePicker } from "antd";
import { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import c from "classnames";

import s from "./style.module.sass";

const { RangePicker } = DatePicker;

type Props = {
  className?: string;
  value: RangeValue<Moment> | undefined;
  fixForFixed?: boolean;
  onChange: (values: RangeValue<Moment>) => void;
}

const DateRangePicker: FC<Props> = ({
  className, value, fixForFixed = true, onChange
}) => {
  return (
    <div className={c(s.container, className)}>
      <RangePicker
        format="DD-MM-YYYY"
        value={value}
        className={s.input}
        onChange={onChange}
        allowClear={false}
        getPopupContainer={fixForFixed ? (trigger) => trigger.parentElement as HTMLElement : undefined}
        inputReadOnly={true}
      />
    </div>
  );
};

export default observer(DateRangePicker);
import { FC, createContext, useState, useEffect, useRef } from "react";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";

import { useAlertStore } from "src/stores/alert";
import { withAuth } from "src/utils/withAuth";
import { deviceService } from "src/services/device";
import { AlertItem } from "src/stores/alert/types";
import { withTryCatch } from "src/utils/withTryCatch";
import Text from "src/ui/typography/Text";

import { DeviceLocalStore } from "./store";
import s from "./style.module.sass";

export const DeviceLocalStoreContext = createContext<DeviceLocalStore | null>(null);

const DeviceProvider: FC = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const { t } = useTranslation();
  const alertStore = useAlertStore();
  const store = useRef(new DeviceLocalStore());
  const match = useRouteMatch<{ id: string }>("/device/:id");

  const getDeviceInfoRequest = async (id: number) => {
    const data = await withAuth(t, async (login, password) => {
      const response = await deviceService.get(login, password, id);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        return {
          id,
          data: data.data
        };
      }

      if (data.error === -8) {
        return {
          id: null,
          data: null,
        };
      }

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };
      alertStore.push(errorAlert);

      return {
        id: null,
        data: null,
      };
    });
    return data;
  };

  useEffect(() => {
    const update = async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          const id = match?.params.id;
          if (id === undefined) {
            setError(true);
            return;
          }
          const data = await getDeviceInfoRequest(+id!)
          if (data && data.data && data.id !== null) {
            store.current.setDevice({
              id: data.id,
              ...data.data
            });
            return;
          }
          setError(true);
        },
        async () => {
          setError(true);
        },
        async () => {
          setTimeout(() => {
            setLoading(false);
          }, 500)
        }
      )
    };
    update();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DeviceLocalStoreContext.Provider value={store.current}>
      <div className={s.provider}>
        { isLoading &&
          <div className={s.loading}>
            <LoadingOutlined className={s.loader} />
          </div>
        }
        { isError &&
          <div className={s.error}>
            <Text className={s.title} size={17} weight={700}>
              Данные отсутствуют или произошла ошибка
            </Text>
          </div>
        }
        { !isLoading && !isError &&
          children
        }
      </div>
    </DeviceLocalStoreContext.Provider>
  );
};

export default observer(DeviceProvider);
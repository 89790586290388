import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";

import { useQuery } from "src/hooks/useQuery";
import { clearStores } from "src/utils/clearStores";
import { storageService } from "src/services/storage";
import { constants } from "src/common/constants";
import { useAuthStore } from "src/stores/auth";

const Demo: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const authStore = useAuthStore();

  useEffect(() => {
    const onMounted = () => {
      const login = query.get("login");
      const password = query.get("password");

      if (login === "demo" && password === "demo") {
        clearStores();
        storageService("sessionStorage").add({
          key: constants.storage.demoStoreKey,
          value: "true"
        });
        authStore.setCredentials(login, password);
        authStore.setAuthorizedState(true);
        authStore.setLastOwnerId("demo");
        history.replace("/");
        return;
      }

      history.replace("/login");
    };

    onMounted();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      DEMO
    </>
  );
};

export default observer(Demo);
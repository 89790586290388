import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object } from "yup";

import { useOwnersStore } from "src/stores/owners";
import { useOwnerStore } from "src/stores/owner";
import { useAuthStore } from "src/stores/auth";
import {
  addressSchema,
  emailSchema,
  innSchema,
  ownerNameSchema,
  phoneSchema,
} from "src/validators";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withAuth } from "src/utils/withAuth";
import { ownerService } from "src/services/owner";
import { withTryCatch } from "src/utils/withTryCatch";

export const useUpdateOwner = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const ownerStore = useOwnerStore();
  const ownersStore = useOwnersStore();
  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      name: ownerStore.name ?? "",
      inn: ownerStore.inn ?? "",
      address: ownerStore.address ?? "",
      email: ownerStore.email ?? "",
      phone: ownerStore.phone ?? "",
    },
    validationSchema: object({
      name: ownerNameSchema(t),
      inn: innSchema(t),
      address: addressSchema(t),
      email: emailSchema(t),
      phone: phoneSchema(t),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await updateOwnerInfoRequest();

          if (isError.current) {
            return;
          }

          await getOwnersRequest();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const updateOwnerInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await ownerService.update(login, password, {
        owner_id: Number(ownerStore.id),
        owner: {
          name: formik.values.name,
          inn: formik.values.inn,
          address: formik.values.address,
          email: formik.values.email,
          phone: formik.values.phone,
        },
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        const successAlert: AlertItem = {
          title: t("common.success"),
          type: "success",
          description: undefined,
        };
        alertStore.push(successAlert);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  const getOwnersRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await ownerService.getAll(login, password);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        const owner =
          data.data.find((owner) => {
            return owner.id === authStore.lastOwnerId;
          }) ?? data.data[0];
        ownerStore.setOwner(owner);
        ownersStore.set(data.data);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  return {
    isLoading,
    formik,
  };
};

import i18next from "i18next";
import moment from "moment";
import "moment/locale/ru";
import { initReactI18next } from "react-i18next";

export const initI18n = async () => {
  const ru = await import("./translation.ru.json");
  await i18next.use(initReactI18next).init({
    resources: {
      ru: ru,
    },
    lng: "ru",
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false,
      format(value, format, lng) {
        if (format === "currency")
          return Intl.NumberFormat(lng, {
            style: "currency",
            currency: "RUB",
            currencyDisplay: "symbol",
          }).format(value);
        if (format === "howLong") {
          return moment
            .utc(value * 1000)
            .locale(lng as string)
            .fromNow();
        }
        if (format === "longDay") {
          return Intl.DateTimeFormat(lng, {
            hour12: false,
            hour: "numeric",
            minute: "numeric",
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            weekday: "long",
          }).format(value);
        }
        if (format === "month") {
          return Intl.DateTimeFormat(lng, {
            hour12: false,
            year: "numeric",
            month: "long",
          }).format(value);
        }
        if (format === "shortDay") {
          return Intl.DateTimeFormat(lng, {
            hour12: false,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            weekday: "long",
          }).format(value);
        }
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
  });
  return i18next;
};

import { action, autorun, makeObservable, observable, toJS } from "mobx";
import { createContext, useContext } from "react";

import { loggerService } from "src/services/logger";

import { UserItem } from "./types";

class UserStore {
  @observable
  login?: string;
  @observable
  name?: string;
  @observable
  phone?: string;
  @observable
  email?: string;
  @observable
  privilege?: string;
  @observable
  registered?: number;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setLogin(login: string) {
    this.login = login;
  }

  @action.bound
  setName(name: string) {
    this.name = name;
  }

  @action.bound
  setPhone(phone: string) {
    this.phone = phone;
  }

  @action.bound
  setEmail(email: string) {
    this.email = email;
  }

  @action.bound
  setPrivilege(privilege: string) {
    this.privilege = privilege;
  }

  @action.bound
  setRegistered(registered: number) {
    this.registered = registered;
  }

  @action.bound
  setUser(user: UserItem) {
    this.login = user.login;
    this.name = user.name;
    this.phone = user.phone;
    this.email = user.email;
    this.privilege = user.privilege;
    this.registered = user.registered;
  }

  @action.bound
  clear() {
    this.login = undefined;
    this.name = undefined;
    this.phone = undefined;
    this.email = undefined;
    this.privilege = undefined;
    this.registered = undefined;
  }
}

export const userStore = new UserStore();

const userProvider = createContext(userStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(userStore), "User Store changed:");
  });
}

export const useUserStore = () => {
  const store = useContext(userProvider);
  return store;
};

import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import Input from "src/ui/inputs/Input";
import SubmitButton from "src/ui/buttons/SubmitButton";

import { useLoginFrom } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
};

const LoginForm: FC<Props> = ({
  className
}) => {
  const { formik, isLoading } = useLoginFrom();

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={c(className, s.form)}
    >
      <Input
        name="login"
        label="Логин"
        placeholder="Введите логин"
        size="large"
        error={formik.errors.login}
        value={formik.values.login}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Input
        name="password"
        label="Пароль"
        placeholder="Введите пароль"
        size="large"
        type="password"
        error={formik.errors.password}
        value={formik.values.password}
        className={s.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <SubmitButton
        isLoading={isLoading}
        className={s.button}
        onClick={formik.handleSubmit}
        size="large"
        isDisabled={!(formik.isValid && formik.dirty)}
      >Войти</SubmitButton>
    </form>
  );
};

export default observer(LoginForm);
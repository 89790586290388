import { FC, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";

import PageContainer from "src/ui/containers/PageContainer";
import Router from "src/ui/layout/Router";
import RouterItem from "src/ui/layout/RouterItem";
import { useTitle } from "src/hooks/useTitle";
import { DeviceLocalStoreContext } from "src/components/providers/DeviceProvider";

import Info from "./info";
import Update from "./update";

const Device: FC = () => {
  const store = useContext(DeviceLocalStoreContext);
  useTitle(store?.name ?? "");

  return (
    <PageContainer
      routes={
        <Router>
          <RouterItem to={`/device/${store?.id}`} title="Общая информация" />
          <RouterItem to={`/device/${store?.id}/update`} title="Обновить" />
        </Router>
      }
    >
      <Switch>
        <Route exact path={`/device/${store?.id}`} component={Info} />
        <Route exact path={`/device/${store?.id}/update`} component={Update} />
      </Switch>
    </PageContainer>
  );
};

export default observer(Device);

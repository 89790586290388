import { FC } from "react";
import { observer } from "mobx-react-lite";

import DevicesList from "src/components/widgets/DevicesList";
import { useTitle } from "src/hooks/useTitle";

const DevicesAll: FC = () => {
  useTitle("Аппараты");

  return (
    <DevicesList />
  );
};

export default observer(DevicesAll);
import { FC } from "react";
import { observer } from "mobx-react-lite";

import { useTitle } from "src/hooks/useTitle";
import EventsList from "src/components/widgets/EventsList";

const ReportsEvents: FC = () => {
  useTitle("События");

  return (
    <EventsList />
  );
};

export default observer(ReportsEvents);
import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, ref } from "yup";

import { userService } from "src/services/user";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { useAuthStore } from "src/stores/auth";
import { withAuth } from "src/utils/withAuth";
import { withTryCatch } from "src/utils/withTryCatch";
import { passwordSchema } from "src/validators";

export const useUpdatePassword = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    },
    validationSchema: object({
      oldPassword: passwordSchema(t).oneOf(
        [authStore.password],
        t("common.old_password_error")
      ),
      newPassword: passwordSchema(t).notOneOf(
        [authStore.password],
        t("common.new_password_error")
      ),
      repeatPassword: passwordSchema(t).oneOf(
        [ref("newPassword")],
        t("common.repeat_password_error")
      ),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await updateUserInfoRequest();

          if (isError.current) {
            return;
          }

          authStore.updatePasword(formik.values.newPassword);
          formik.resetForm();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const updateUserInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await userService.update(login, password, {
        login: login,
        password: formik.values.newPassword,
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        const successAlert: AlertItem = {
          title: t("common.success"),
          type: "success",
        };
        alertStore.push(successAlert);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  return {
    isLoading,
    formik,
  };
};

import { FC } from "react";
import { observer } from "mobx-react-lite";

import AddReader from "src/components/forms/AddReader";
import ReaderMessagePing from "src/components/forms/ReaderMessagePing";
import ReaderMessageNotify from "src/components/forms/ReaderMessageNotify";
import ReaderMessageSale from "src/components/forms/ReaderMessageSale";
import PageContainer from "src/ui/containers/PageContainer";
import { useTitle } from "src/hooks/useTitle";

import s from "./style.module.sass";

const Debug: FC = () => {
  useTitle("Debug");

  return (
    <>
      <PageContainer>
        <div className={s.wrap}>
          <AddReader className={s.forms} />
          <ReaderMessageSale className={s.forms} />
          <ReaderMessagePing className={s.forms} />
          <ReaderMessageNotify className={s.forms} />
        </div>
      </PageContainer>
    </>
  );
};

export default observer(Debug);

export const constants = {
  network: {
    baseURL: "http://www.i-pay.ru/api/v1",
    timeout: 60000,
  },
  storage: {
    loginStoreKey: "__iPayStoreObjectLG",
    passwordStoreKey: "__iPayStoreObjectPS",
    isAuthorizedStoreKey: "__iPayStoreObjectIAuth",
    ownerIdStoreKey: "__iPayStoreObjectOId",
    demoStoreKey: "__iPaySessionDemo",
  },
  appVersion: process.env.REACT_APP_VERSION,
};

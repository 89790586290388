import { makeObservable, observable, action } from "mobx";

import { GetDevicesDTO } from "src/services/device/types";

export class DeviceLocalStore {
  @observable
  id: string | number | undefined;
  @observable
  name: string | undefined;
  @observable
  sn: string | undefined;
  @observable
  address: string | undefined;
  @observable
  cash_total: string | undefined;
  @observable
  cashless_total: string | undefined;
  @observable
  reader_sn: string | undefined;
  @observable
  last_action: number | null = null;
  @observable
  last_ping: number | null = null;
  @observable
  notify: Array<number> = [];
  @observable
  total: number | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  setDevice(device: GetDevicesDTO) {
    this.id = device.id;
    this.name = device.name;
    this.sn = device.sn;
    this.address = device.address;
    this.cash_total = device.cash_total;
    this.cashless_total = device.cashless_total;
    this.reader_sn = device.reader_sn;
    this.last_action = device.last_action;
    this.last_ping = device.last_ping;
    this.notify = device.notify;
    this.total = device.total;
  }

  @action
  clear() {
    this.id = undefined;
    this.name = undefined;
    this.sn = undefined;
    this.address = undefined;
    this.cash_total = undefined;
    this.cashless_total = undefined;
    this.reader_sn = undefined;
    this.last_action = null;
    this.last_ping = null;
    this.notify = [];
    this.total = undefined;
  }
}

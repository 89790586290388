import { useState } from "react";
import { useTranslation } from "react-i18next";

import { reportService } from "src/services/report";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withAuth } from "src/utils/withAuth";
import { withTryCatch } from "src/utils/withTryCatch";

export const useDevicesCount = () => {
  const [isLoading, setLoading] = useState(true);
  const [devicesCount, setCount] = useState({
    online: 0,
    offline: 0,
    warning: 0,
  });

  const alertStore = useAlertStore();
  const { t } = useTranslation();

  const update = async () => {
    await withTryCatch(
      t,
      async () => {
        setLoading(true);
        await getDevicesCountRequest();
      },
      undefined,
      async () => {
        setLoading(false);
      }
    );
  };

  const getDevicesCountRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await reportService.getDevicesCount(login, password);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        setCount(data.data);
        return;
      }

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };
      errorAlert.description = t("network.unknown_error");
      alertStore.push(errorAlert);
    });
  };

  return {
    isLoading,
    devicesCount,
    update,
  };
};

import { useHistory } from "react-router";

import { storageService } from "src/services/storage";
import { clearStores } from "src/utils/clearStores";

export const useLogout = () => {
  const history = useHistory();

  const logout = () => {
    history.replace("/login");
    storageService("localStorage").clear();
    storageService("sessionStorage").clear();
    clearStores();
  };

  return logout;
};

import axios, { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";

import { constants } from "src/common/constants";
import { storageService } from "src/services/storage";

const axiosDefault: AxiosInstance = axios.create({
  baseURL: constants.network.baseURL,
  timeout: constants.network.timeout,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosMock: AxiosInstance = axios.create({
  baseURL: constants.network.baseURL,
  timeout: constants.network.timeout,
  headers: {
    "Content-Type": "application/json",
  },
});

export const mock = new MockAdapter(axiosMock, { delayResponse: 200 });

export const networkService = (): AxiosInstance => {
  const isDemo =
    storageService("sessionStorage").get(constants.storage.demoStoreKey) ===
    "true";
  if (isDemo) {
    return axiosMock;
  }
  return axiosDefault;
};

import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { object } from "yup";

import { withTryCatch } from "src/utils/withTryCatch";
import { authService } from "src/services/auth";
import { AlertItem } from "src/stores/alert/types";
import { useAlertStore } from "src/stores/alert";
import { loginSchema, passwordSchema } from "src/validators";
import { userService } from "src/services/user";
import { useUserStore } from "src/stores/user";
import { useOwnerStore } from "src/stores/owner";
import { useOwnersStore } from "src/stores/owners";
import { useAuthStore } from "src/stores/auth";
import { ownerService } from "src/services/owner";

export const useLoginFrom = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const { t } = useTranslation();
  const history = useHistory();

  const alertStore = useAlertStore();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const ownerStore = useOwnerStore();
  const ownersStore = useOwnersStore();

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema: object({
      login: loginSchema(t),
      password: passwordSchema(t),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        // callback
        async () => {
          setLoading(true);
          await checkAuthRequest();

          if (isError.current === false) {
            await getUserInfoRequest();
          } else {
            return;
          }

          if (isError.current === false) {
            await getOwnersRequest();
          } else {
            return;
          }

          if (isError.current === false) {
            authStore.setAuthorizedState(true);
            history.replace("/");
          }
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const checkAuthRequest = async () => {
    const response = await authService.check(
      formik.values.login,
      formik.values.password
    );
    const data = response.data;

    if (data.error === 0 && data.description === "OK") {
      return;
    }

    isError.current = true;

    const error: AlertItem = {
      type: "error",
      title: t("common.error"),
    };

    if (data.error === -10) {
      error.description = t("login_page.incorrect_login_password");
      alertStore.push(error);
      return;
    }

    if (data.error === -11) {
      error.description = t("login_page.incorrect_password");
      alertStore.push(error);
      return;
    }

    error.description = t("network.unknown_error");
    alertStore.push(error);
  };

  const getUserInfoRequest = async () => {
    const response = await userService.get(
      formik.values.login,
      formik.values.password
    );
    const data = response.data;

    if (data.error === 0 && data.description === "OK" && data.data) {
      userStore.setUser(data.data);
      authStore.setCredentials(formik.values.login, formik.values.password);
      return;
    }

    isError.current = true;

    const error: AlertItem = {
      title: t("common.error"),
      type: "error",
      description: t("network.unknown_error"),
    };
    alertStore.push(error);
  };

  const getOwnersRequest = async () => {
    const response = await ownerService.getAll(
      formik.values.login,
      formik.values.password
    );
    const data = response.data;

    if (data.error === 0 && data.description === "OK" && data.data) {
      ownerStore.setOwner(data.data[0]);
      ownersStore.set(data.data);
      authStore.setLastOwnerId(data.data[0].id);
      return;
    }

    isError.current = true;

    const error: AlertItem = {
      title: t("common.error"),
      type: "error",
      description: t("network.unknown_error"),
    };
    alertStore.push(error);
  };

  return {
    isLoading,
    formik,
  };
};

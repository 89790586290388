import { observable, action, makeObservable, autorun, toJS } from "mobx";
import { useContext, createContext } from "react";
import { loggerService } from "src/services/logger";
import { v4 } from "uuid";

import { AlertItem } from "./types";

class AlertStore {
  @observable items: Array<AlertItem> = [];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  push(item: AlertItem) {
    const newItem = { ...item, id: v4() };
    this.items.push(newItem);

    setTimeout(() => {
      for (let item of this.items) {
        if (item.id === newItem.id) {
          this.remove(item);
          break;
        }
      }
    }, 5000);
  }

  @action.bound
  remove(item: AlertItem) {
    const index = this.items.indexOf(item);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  @action.bound
  clear() {
    this.items = [];
  }
}

export const alertStore = new AlertStore();

const alertProvider = createContext(alertStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(alertStore), "Alert Store changed:");
  });
}

export const useAlertStore = () => {
  const store = useContext(alertProvider);
  return store;
};

import { FC, useState, FocusEvent } from "react";
import { observer } from "mobx-react-lite";
import { Select as SelectAntd } from "antd";
import c from "classnames";

import s from "./style.module.sass";

const { Option } = SelectAntd;

type Props = {
  className?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  value: string;
  name: string;
  label?: string;
  values: {
    title: string;
    value: string;
  }[],
  isLoading?: boolean;
  fixForFixed?: boolean;
  onChange: (value: string) => void;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
};

const Select: FC<Props> = ({
  className, error, value, values, name, label, placeholder, disabled = false, isLoading = false, fixForFixed = true, onChange, onBlur
}) => {

  const [isActive, setActive] = useState(false);

  const labelClass = (() => {
    if (error !== undefined) {
      return s.labelError;
    }
    if (isActive) {
      return s.labelActive;
    }
  })();

  const errorClass = (() => {
    if (error) {
      return s.error;
    }
  })();

  const innerValue = (() => {
    if (value.length === 0) {
      return undefined;
    }
    return value;
  })();

  const onFocus = () => {
    setActive(true);
  };

  const onInnerBlur = (e: FocusEvent<HTMLElement>) => {
    setActive(false);
    if (onBlur !== undefined && value.length !== 0) {
      onBlur(e);
    }
  };

  return (
    <div className={c(s.container, className)}>
      { label &&
        <label className={c(s.label, labelClass)} htmlFor={name}>
          { error ?? label }
        </label>
      }
      <SelectAntd
        id={name}
        value={innerValue}
        className={c(s.input, errorClass)}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onInnerBlur}
        loading={isLoading}
        getPopupContainer={fixForFixed ? (trigger) => trigger.parentElement as HTMLElement : undefined}
      >
        { values.map((value) =>
          <Option
            key={value.value}
            value={value.value}
          >{ value.title }</Option>
        )}
      </SelectAntd>
    </div>
  );
};

export default observer(Select);
import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  className?: string;
};

const Router: FC<Props> = ({
  className, children
}) => {
  return (
    <div className={c(s.router, className)}>
      <div className={s.wrap}>
        { children }
      </div>
    </div>
  );
};

export default observer(Router);
import { networkService } from "src/services/network";
import { loggerService } from "src/services/logger";
import { CheckAuthRes, IAuthService } from "./types";

export const authService: IAuthService = {
  async check(login: string, password: string): Promise<CheckAuthRes> {
    const response = await networkService().post<null, CheckAuthRes>(
      "/CheckAuth",
      null,
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/CheckAuth: ");
    return response;
  },
};

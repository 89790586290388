import { FC, ChangeEvent, KeyboardEvent, FocusEvent, useState } from "react";
import { observer } from "mobx-react-lite";
import { Input as AntdInput } from "antd";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  size?: "large";
  disabled?: boolean;
  value: string;
  name: string;
  label: string;
  placeholder?: string;
  error?: string;
  className?: string;
  type?: "password";
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

const Input: FC<Props> = ({
  value, name, size, label, placeholder, className, error, type, disabled = false,
  onChange, onPressEnter, onBlur
}) => {

  const [isActive, setActive] = useState(false);

  const labelClass = (() => {
    if (error !== undefined) {
      return s.labelError;
    }
    if (isActive) {
      return s.labelActive;
    }
  })();

  const sizeClass = (() => {
    if (size === "large") {
      return s.big;
    }
    return s.small;
  })();

  const errorClass = (() => {
    if (error) {
      return s.error;
    }
  })();

  const onFocus = () => {
    setActive(true);
  };

  const onInnerBlur = (e: FocusEvent<HTMLInputElement>) => {
    setActive(false);
    if (onBlur !== undefined && value.length !== 0) {
      onBlur(e);
    }
  };

  return (
    <div className={c(s.container, className)}>
      <label className={c(s.label, labelClass)} htmlFor={name}>
        { error ?? label }
      </label>
      <AntdInput
        id={name}
        name={name}
        value={value}
        size={size}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        className={c(s.input, sizeClass, errorClass)}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onBlur={onInnerBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

export default observer(Input);
import { FC } from "react";
import { observer } from "mobx-react-lite";

import s from "./style.module.sass";

const DefaultLayout: FC = ({ children }) => {
  return (
    <div className={s.wrap}>
      { children }
    </div>
  );
}

export default observer(DefaultLayout);
import { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";

import s from "./style.module.sass";

type Props = {
  className?: string;
  src: string;
  alt: string;
};

const LocalImage: FC<Props> = ({
  className, src, alt
}) => {
  const absoluteSrc = useMemo(() => {
    return require(`src/assets/img/${src}`).default;
  }, [src]);

  return (
    <picture className={className}>
      <img className={s.img} src={absoluteSrc} alt={alt} />
    </picture>
  )
};

export default observer(LocalImage);
import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import PageContainer from "src/ui/containers/PageContainer";
import DevicesCount from "src/components/widgets/DevicesCount";
import SalesByPeriod from "src/components/widgets/SalesByPeriod";
import TotalSales from "src/components/widgets/TotalSales";
import { useTitle } from "src/hooks/useTitle";

import s from "./style.module.sass";

const Dashboard: FC = () => {
  useTitle("Dashboard");

  return (
    <PageContainer>
      <DevicesCount />
      <div className={c(s.wrap, s.mt)}>
        <SalesByPeriod className={s.forms} />
        <TotalSales className={s.second} />
      </div>
    </PageContainer>
  );
};

export default observer(Dashboard);

import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Divider } from "antd";
import c from "classnames";

import Text from "src/ui/typography/Text";

import s from "./style.module.sass";

type Props = {
  title: string;
  className?: string;
};

const FormContainer: FC<Props> = ({
  children, title, className
}) => {
  return (
    <div className={c(s.container, className)}>
      <div className={s.wrap}>
        <Text className={s.title} weight={700}>{ title }</Text>
        { children }
      </div>
      <Divider className={s.divider} />
    </div>
  );
};

export default observer(FormContainer);
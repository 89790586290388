import { FC, ChangeEvent, KeyboardEvent, FocusEvent } from "react";
import { observer } from "mobx-react-lite";
import { Input } from "antd";
import c from "classnames";

import s from "./style.module.sass";
import LocalImage from "src/ui/image/LocalImage";


type Props = {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string | null;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
};

const Search: FC<Props> = ({
  className, placeholder, disabled = false, value, name, onChange, onPressEnter, onBlur
}) => {
  return (
    <div className={c(s.container, className)}>
      <Input
        id={name}
        name={name}
        className={s.input}
        placeholder={placeholder}
        disabled={disabled}
        value={value ?? ""}
        onChange={onChange}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        autoComplete="off"
      />
      <LocalImage
        className={s.icon}
        src="search.svg"
        alt="search"
      />
    </div>
  );
};

export default observer(Search);
import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import UpdateOwner from "src/components/forms/UpdateOwner";
import AddOwner from "src/components/forms/AddOwner";
import { useTitle } from "src/hooks/useTitle";

import s from "./style.module.sass";

const ProfileCompany: FC = () => {
  useTitle("Организация");
  return (
    <>
      <div className={s.wrap}>
        <UpdateOwner className={s.forms} />
        <AddOwner className={c(s.forms, s.second)} />
      </div>
    </>
  );
};

export default observer(ProfileCompany);
import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useLogout } from "src/hooks/useLogout";

const Logout: FC = () => {
  const logout = useLogout();

  useEffect(() => {
    logout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null;
};

export default observer(Logout);
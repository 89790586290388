import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { Moment } from "moment";

import { debugService } from "src/services/debug";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withTryCatch } from "src/utils/withTryCatch";
import { apikeySchema, snSchema } from "src/validators";

export const useReaderMessage = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik<{
    sn: string;
    apikey: string;
    code: string;
    value: string;
    timestamp?: Moment;
  }>({
    initialValues: {
      sn: "",
      apikey: "",
      code: "",
      value: "",
      timestamp: undefined,
    },
    validationSchema: object({
      sn: snSchema(t),
      apikey: apikeySchema(t),
      code: string()
        .required(t("common.code_required"))
        .min(1, t("common.min_1"))
        .max(10, t("common.max_10")),
      value: string()
        .required(t("common.value_notify_required"))
        .oneOf(["0", "1"], t("common.value_notify_error")),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await sendReaderMessageNotify();

          if (isError.current) {
            return;
          }

          formik.resetForm();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const sendReaderMessageNotify = async () => {
    const response = await debugService.notify({
      sn: formik.values.sn,
      apikey: formik.values.apikey,
      code: formik.values.code,
      value: formik.values.value as "0" | "1",
      timestamp: formik.values.timestamp?.utc(true).unix(),
    });
    const data = response.data;

    if (data.error === 0 && data.description === "OK") {
      const successAlert: AlertItem = {
        title: t("common.success"),
        type: "success",
      };
      alertStore.push(successAlert);
      return;
    }

    isError.current = true;

    const errorAlert: AlertItem = {
      title: t("common.error"),
      type: "error",
    };

    errorAlert.description = t("network.unknown_error");
    alertStore.push(errorAlert);
  };

  return {
    isLoading,
    formik,
  };
};

import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object } from "yup";

import { debugService } from "src/services/debug";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withTryCatch } from "src/utils/withTryCatch";
import { apikeySchema, pinSchema, snSchema } from "src/validators";

export const useAddReader = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      sn: "",
      pin: "",
      apikey: "",
    },
    validationSchema: object({
      sn: snSchema(t),
      pin: pinSchema(t),
      apikey: apikeySchema(t),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await addReaderRequest();

          if (isError.current) {
            return;
          }

          formik.resetForm();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const addReaderRequest = async () => {
    const response = await debugService.addReader({
      sn: formik.values.sn,
      pin: formik.values.pin,
      apikey: formik.values.apikey,
    });
    const data = response.data;

    if (data.error === 0 && data.description === "OK") {
      const successAlert: AlertItem = {
        title: t("common.success"),
        type: "success",
      };
      alertStore.push(successAlert);
      return;
    }

    isError.current = true;

    const errorAlert: AlertItem = {
      title: t("common.error"),
      type: "error",
    };

    if (data.error === -8 && data.description === "Reader already exist") {
      errorAlert.description = t("network.reader_already_exist_error");
      alertStore.push(errorAlert);
      return;
    }

    errorAlert.description = t("network.unknown_error");
    alertStore.push(errorAlert);
  };

  return {
    isLoading,
    formik,
  };
};

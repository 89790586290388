import { FC } from "react";
import { observer } from "mobx-react-lite";

import FormContainer from "src/ui/containers/FormContainer";
import Input from "src/ui/inputs/Input";
import SubmitButton from "src/ui/buttons/SubmitButton";

import { useAddOwner } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
};

const AddOwner: FC<Props> = ({
  className
}) => {
  const { isLoading, formik } = useAddOwner();

  return (
    <FormContainer title="Добавить организацию" className={className}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className={s.form}
      >
        <Input
          name="name"
          label="Наименование"
          placeholder="Введите наименование"
          error={formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="inn"
          label="ИНН"
          placeholder="Введите ИНН"
          className={s.input}
          error={formik.errors.inn}
          value={formik.values.inn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="address"
          label="Адрес"
          placeholder="Введите адрес"
          className={s.input}
          error={formik.errors.address}
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="phone"
          label="Телефон"
          placeholder="Введите телефон"
          className={s.input}
          error={formik.errors.phone}
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="email"
          label="Почта"
          placeholder="Введите почту"
          className={s.input}
          error={formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <SubmitButton
          className={s.button}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          isDisabled={!(formik.isValid && formik.dirty)}
        >Обновить</SubmitButton>
      </form>
    </FormContainer>
  )
};

export default observer(AddOwner);
import { FC } from "react";
import { observer } from "mobx-react-lite";

import UpdateDevice from "src/components/forms/UpdateDevice";

import s from "./style.module.sass";

const DeviceUpdate: FC = () => {
  return (
    <div className={s.wrap}>
      <UpdateDevice className={s.forms} />
    </div>
  );
};

export default observer(DeviceUpdate);
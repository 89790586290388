import { useEffect, useRef } from "react";
import { useLocalObservable } from "mobx-react-lite";

export const useWindowResize = (callback?: () => void) => {
  const windowSize = useLocalObservable(() => ({
    width: window.innerWidth,
    height: window.innerHeight,
    setSize(width: number, height: number) {
      this.width = width;
      this.height = height;
    },
  }));

  const _callback = useRef(callback);

  useEffect(() => {
    _callback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      windowSize.setSize(window.innerWidth, window.innerHeight);

      if (_callback.current !== undefined) {
        _callback.current();
      }
    };

    handler();

    window.addEventListener("resize", handler);
    window.addEventListener("orientationchange", handler);

    return () => {
      window.removeEventListener("resize", handler);
      window.removeEventListener("orientationchange", handler);
    };
  }, [windowSize]);

  return {
    width: windowSize.width,
    height: windowSize.height,
  };
};

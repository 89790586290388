/* eslint-disable react-hooks/exhaustive-deps */
import { useState, ChangeEvent, useEffect } from "react";
import { debounce, uniqBy } from "lodash";
import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
  reaction,
} from "mobx";
import { TFunction, useTranslation } from "react-i18next";

import { withAuth } from "src/utils/withAuth";
import { AlertItem } from "src/stores/alert/types";
import { withTryCatch } from "src/utils/withTryCatch";
import { deviceService } from "src/services/device";
import { GetDevicesDTO } from "src/services/device/types";
import { alertStore } from "src/stores/alert";
import { useOwnerStore } from "src/stores/owner";

class DevicesListStore {
  id: number;
  perPage = 10;
  t: TFunction;

  @observable page = 0;
  @observable count = 0;
  @observable isLoading = false;
  @observable search: string | null = null;
  @observable devices: GetDevicesDTO[] = [];

  debounceUpdate = debounce(this.update, 500);

  constructor(t: TFunction, id: number) {
    this.t = t;
    this.id = id;
    makeObservable(this);
  }

  @computed
  get displayDevices() {
    return uniqBy(this.devices, (e) => {
      return e.id;
    });
  }

  @computed
  get canLoad() {
    if (
      this.displayDevices.length === this.count ||
      this.count === 0 ||
      this.displayDevices.length === 0
    ) {
      return false;
    }
    return true;
  }

  @computed
  get pageAmount() {
    return Math.trunc(this.count / this.perPage) + 1;
  }

  @computed
  get isEmpty() {
    return this.count === 0;
  }

  @computed
  get searchValue() {
    if (this.search?.length === 0) {
      return undefined;
    }
    if (this.search === null) {
      return undefined;
    }
    return this.search;
  }

  @action.bound
  setPage(value: number) {
    this.page = value;
  }

  @action.bound
  onChange(e: ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      this.page = 0;
      this.devices = [];
      this.count = 0;
      this.search = e.target.value;
    });
  }

  @action.bound
  async getDevicesRequest() {
    await withAuth(this.t, async (login, password) => {
      const response = await deviceService.getAll(login, password, {
        owner_id: this.id,
        start_line: this.perPage * this.page + 1,
        line_count: this.perPage,
        filtered_by: "*",
        filter: this.searchValue,
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        runInAction(() => {
          if (data.data && data.total_lines) {
            if (this.displayDevices.length > data.total_lines) {
              this.devices = [];
            }
            this.count = data.total_lines;
            this.devices.push(...data.data);
          }
        });
        return;
      }

      const error: AlertItem = {
        title: this.t("common.error"),
        type: "error",
        description: this.t("network.unknown_error"),
      };
      runInAction(() => {
        alertStore.push(error);
      });
    });
  }

  @action.bound
  async update() {
    await withTryCatch(
      this.t,
      async () => {
        runInAction(() => {
          this.isLoading = true;
        });
        await this.getDevicesRequest();
      },
      undefined,
      async () => {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    );
  }
}

export const useDevicesList = () => {
  const { t } = useTranslation();
  const ownerStore = useOwnerStore();
  const [store] = useState(
    () => new DevicesListStore(t, Number(ownerStore.id))
  );

  useEffect(() => {
    store.update();

    const disposer = reaction(
      () => [store.page, store.search],
      async () => {
        await store.debounceUpdate();
      }
    );

    return () => {
      disposer();
    };
  }, []);

  return store;
};

import { action, autorun, makeObservable, observable, toJS } from "mobx";
import { createContext, useContext } from "react";

import { loggerService } from "src/services/logger";
import { authStore } from "src/stores/auth";

import { OwnerItem } from "./types";

class OwnerStore {
  @observable
  id?: number | string;
  @observable
  name?: string;
  @observable
  inn?: string;
  @observable
  registered?: number;
  @observable
  address?: string;
  @observable
  phone?: string;
  @observable
  email?: string;
  @observable
  timezone?: string;
  @observable
  currency?: string;
  @observable
  language?: string;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setName(name: string) {
    this.name = name;
  }

  @action.bound
  setInn(inn: string) {
    this.inn = inn;
  }

  @action.bound
  setAddress(address: string) {
    this.address = address;
  }

  @action.bound
  setPhone(phone: string) {
    this.phone = phone;
  }

  @action.bound
  setEmail(email: string) {
    this.email = email;
  }

  @action.bound
  setTimezone(timezone: string) {
    this.timezone = timezone;
  }

  @action.bound
  setCurrency(currency: string) {
    this.currency = currency;
  }

  @action.bound
  setLanguage(language: string) {
    this.language = language;
  }

  @action.bound
  setOwner(owner: OwnerItem) {
    this.id = owner.id;
    this.name = owner.name;
    this.inn = owner.inn;
    this.registered = owner.registered;
    this.address = owner.address;
    this.phone = owner.phone;
    this.email = owner.email;
    this.timezone = owner.timezone;
    this.currency = owner.currency;
    this.language = owner.language;
    if (owner.id) {
      authStore.setLastOwnerId(`${owner.id}`);
    }
  }

  @action.bound
  clear() {
    this.id = undefined;
    this.name = undefined;
    this.inn = undefined;
    this.registered = undefined;
    this.address = undefined;
    this.phone = undefined;
    this.email = undefined;
    this.timezone = undefined;
    this.currency = undefined;
    this.language = undefined;
  }
}

export const ownerStore = new OwnerStore();

const ownerProvider = createContext(ownerStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(ownerStore), "Owner Store changed:");
  });
}

export const useOwnerStore = () => {
  const store = useContext(ownerProvider);
  return store;
};

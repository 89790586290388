import { FC } from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import c from "classnames";

import Text from "src/ui/typography/Text";

import s from "./style.module.sass";

type Props = {
  className?: string;
  to: string;
  title: string;
}

const RouterItem: FC<Props> = ({
  className, to, title
}) => {
  return (
    <NavLink exact activeClassName={s.active} className={c(className, s.link)} to={to}>
      <Text size={13} weight={600}>
        { title }
      </Text>
    </NavLink>
  );
};

export default observer(RouterItem);
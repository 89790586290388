import { FC, StyleHTMLAttributes, useMemo } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  size?: number;
  weight?: number;
  className?: string;
};

const Text: FC<Props> = ({size = 16, weight = 400, className, children}) => {
  const style = useMemo(() => {
    return {
      "--font-size": `${size}px`,
      "--font-weight": weight,
      "--line-height": `${size * 1.5}px`
    } as StyleHTMLAttributes<unknown>
  }, [size, weight])

  return (
    <p
      style={style}
      className={c(s.text, className)}
    >
      { children }
    </p>
  );
};

export default observer(Text);
import { FC } from "react";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

const Page404: FC = () => {
  const location = useLocation();

  return (
    <>
      { location.pathname + " Not Found, 404" }
    </>
  );
};

export default observer(Page404);
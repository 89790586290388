import { FC } from "react";
import { observer } from "mobx-react-lite";

import AddDevice from "src/components/forms/AddDevice";
import { useTitle } from "src/hooks/useTitle";

import s from "./style.module.sass";

const DevicesAdd: FC = () => {
  useTitle("Добавить устройство");

  return (
    <div className={s.wrap}>
      <AddDevice className={s.forms} />
    </div>
  );
};

export default observer(DevicesAdd);
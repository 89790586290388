import { FC } from "react";
import { Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

import PageContainer from "src/ui/containers/PageContainer";
import Router from "src/ui/layout/Router";
import RouterItem from "src/ui/layout/RouterItem";

import Events from "./events";

const Reports: FC = () => {
  return (
    <PageContainer
      routes={
        <Router>
          <RouterItem to="/reports" title="События" />
        </Router>
      }
    >
      <Switch>
        <Route exact path="/reports" component={Events} />
      </Switch>
    </PageContainer>
  );
};

export default observer(Reports);

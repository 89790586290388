import { networkService, mock } from "src/services/network";
import { loggerService } from "src/services/logger";
import { databaseService } from "src/services/database";
import {
  IUserService,
  UserInfoRes,
  UpdateUserInfoReq,
  UpdateUserInfoRes,
} from "./types";

export const userService: IUserService = {
  async get(login: string, password: string): Promise<UserInfoRes> {
    const response = await networkService().post<null, UserInfoRes>(
      "/GetUserInfo",
      null,
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetUserInfo: ");
    return response;
  },

  async update(
    login: string,
    password: string,
    model: UpdateUserInfoReq
  ): Promise<UpdateUserInfoRes> {
    const response = await networkService().post<
      UpdateUserInfoReq,
      UpdateUserInfoRes
    >("/UpdateUserInfo", model, {
      headers: {
        login,
        password,
      },
    });
    loggerService(response, "/UpdateUserInfo: ");
    return response;
  },
};

// Mock
mock.onPost("/GetUserInfo").reply(() => {
  return [
    200,
    {
      error: 0,
      description: "OK",
      data: { ...databaseService.user },
    },
  ];
});

mock.onPost("/UpdateUserInfo").reply((config) => {
  const data = JSON.parse(config.data);
  databaseService.user.login = data.login;
  databaseService.user.name = data.name;
  databaseService.user.phone = data.phone;
  databaseService.user.email = data.email;

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

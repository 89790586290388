import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeviceLocalStoreContext } from "src/components/providers/DeviceProvider";
import { reportService } from "src/services/report";

import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withAuth } from "src/utils/withAuth";
import { withTryCatch } from "src/utils/withTryCatch";

export const useSalesByPeriod = (forDevice: boolean = false) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({
    today: 0,
    yesterday: 0,
    week: 0,
    month: 0,
  });

  const store = useContext(DeviceLocalStoreContext);
  const alertStore = useAlertStore();
  const { t } = useTranslation();

  const update = async () => {
    await withTryCatch(
      t,
      async () => {
        setLoading(true);
        await getSalesByPeriodRequest();
      },
      undefined,
      async () => {
        setLoading(false);
      }
    );
  };

  const getSalesByPeriodRequest = async () => {
    await withAuth(t, async (login, password) => {
      const deviceID = forDevice ? Number(store?.id) : undefined;
      const response = await reportService.getSalesByPeriod(
        login,
        password,
        deviceID
      );
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        setData(data.data);
        return;
      }

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };
      errorAlert.description = t("network.unknown_error");
      alertStore.push(errorAlert);
    });
  };

  return {
    isLoading,
    data,
    update,
  };
};

import ReactDOM from "react-dom";
import ruRU from "antd/lib/locale/ru_RU";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Chart, registerables } from "chart.js";

import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import { initI18n } from "src/i18n";
import { initFromStorage } from "src/utils/initFromStorage";

import "antd/dist/antd.css";
import "src/assets/sass/common.sass";

Chart.register(...registerables);
initFromStorage();

initI18n().then(() => {
  ReactDOM.render(
    <BrowserRouter>
      <ConfigProvider locale={ruRU}>
        <App />
      </ConfigProvider>
    </BrowserRouter>,
    document.getElementById("app")
  );
});

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

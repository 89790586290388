import { FC } from "react";
import { Switch, Route } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Layouts from "src/components/layouts";
import Login from "src/pages/auth/Login";
import Dashboard from "src/pages/account/Dashboard";
import Profile from "src/pages/account/Profile";
import Reports from "src/pages/account/Reports";
import Devices from "src/pages/account/Devices";
import Device from "src/pages/account/Device";
import Debug from "src/pages/account/Debug";
import Company from "src/pages/account/Company";
import Logout from "src/pages/Logout";
import Demo from "src/pages/Demo";
import Page404 from "src/pages/404";
import DeviceProvider from "src/components/providers/DeviceProvider";

import PrivateRoute from "./PrivateRoute";

const Router: FC = () => {
  return (
    <Switch>
      <Route exact path={["/logout", "/demo"]}>
        <Layouts.Default>
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/demo" component={Demo} />
          </Switch>
        </Layouts.Default>
      </Route>
      <Route exact path={["/login", "/reg"]}>
        <Layouts.Auth>
          <Switch>
            <Route path="/login" component={Login} />
          </Switch>
        </Layouts.Auth>
      </Route>
      <PrivateRoute exact path={[
        "/",
        "/company",
        "/profile",
        "/profile/company",
        "/reports",
        "/devices",
        "/devices/add",
        "/device/:id",
        "/device/:id/update",
        "/debug",
        "/debug/sale",
        "/debug/ping",
        "/debug/notify"
      ]}>
        <Layouts.Account>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/profile" component={Profile} />
            <Route path="/reports" component={Reports} />
            <Route path="/devices" component={Devices} />
            <Route path="/device/:id">
              <DeviceProvider>
                <Device />
              </DeviceProvider>
            </Route>
            <Route path="/debug" component={Debug} />
            <Route path="/company" component={Company} />
          </Switch>
        </Layouts.Account>
      </PrivateRoute>
      <Route path="*">
        <Layouts.Default>
          <Page404 />
        </Layouts.Default>
      </Route>
    </Switch>
  );
};

export default observer(Router);
import { TFunction } from "react-i18next";

import { AlertItem } from "src/stores/alert/types";
import { alertStore } from "src/stores/alert/";

export const withTryCatch = async (
  t: TFunction,
  callback: () => Promise<void>,
  fallback?: () => Promise<void>,
  finallyback?: () => Promise<void>
) => {
  try {
    await callback();
  } catch (error: any) {
    const errorAlert: AlertItem = {
      type: "error",
      title: t("common.error"),
    };

    if (error.response) {
      errorAlert.description = t("network.server_error");
    } else if (error.request) {
      errorAlert.description = t("network.client_error");
    } else {
      errorAlert.description = t("network.unknown_error");
    }

    alertStore.push(errorAlert);

    if (fallback !== undefined) {
      await fallback();
    }
  } finally {
    if (finallyback !== undefined) {
      await finallyback();
    }
  }
};

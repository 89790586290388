import { FC } from "react";
import { observer } from "mobx-react-lite";

import FormContainer from "src/ui/containers/FormContainer";
import Input from "src/ui/inputs/Input";
import SubmitButton from "src/ui/buttons/SubmitButton";

import { useUpdatePassword } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
}

const UpdatePassword: FC<Props> = ({
  className
}) => {
  const { isLoading, formik } = useUpdatePassword();

  return (
    <FormContainer className={className} title="Обновить пароль">
      <form
        onSubmit={(e) => e.preventDefault()}
        className={s.form}
      >
        <Input
          name="oldPassword"
          label="Старый пароль"
          placeholder="Введите пароль"
          type="password"
          error={formik.errors.oldPassword}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="newPassword"
          label="Новый пароль"
          placeholder="Введите пароль"
          type="password"
          className={s.input}
          error={formik.errors.newPassword}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="repeatPassword"
          label="Повторите пароль"
          placeholder="Введите пароль"
          type="password"
          className={s.input}
          error={formik.errors.repeatPassword}
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <SubmitButton
          className={s.button}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          isDisabled={!(formik.isValid && formik.dirty)}
        >Обновить</SubmitButton>
      </form>
    </FormContainer>
  );
};

export default observer(UpdatePassword);
import { StorageService, StorageItem } from "./types";

export const sessionStorageService: StorageService = {
  get isSupport(): boolean {
    return (
      typeof window["sessionStorage"] != "undefined" &&
      window["sessionStorage"] != null
    );
  },

  add(data: StorageItem): void {
    if (this.isSupport) {
      window.sessionStorage.setItem(data.key, data.value);
    }
  },

  get(key: string): string | null | undefined {
    if (this.isSupport) {
      const item = window.sessionStorage.getItem(key);
      return item;
    } else {
      return null;
    }
  },

  remove(key: string): void {
    if (this.isSupport) {
      window.sessionStorage.removeItem(key);
    }
  },

  clear(): void {
    if (this.isSupport) {
      window.sessionStorage.clear();
    }
  },
};

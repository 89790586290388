import { mock, networkService } from "src/services/network";
import { loggerService } from "src/services/logger";
import { databaseService } from "src/services/database";
import {
  AddReaderRequest,
  AddReaderResponse,
  IDebugService,
  ReaderMessageNotifyRequest,
  ReaderMessageNotifyResponse,
  ReaderMessagePingRequest,
  ReaderMessagePingResponse,
  ReaderMessageSaleRequest,
  ReaderMessageSaleResponse,
} from "./types";

export const debugService: IDebugService = {
  async addReader(model: AddReaderRequest): Promise<AddReaderResponse> {
    const response = await networkService().post<
      AddReaderRequest,
      AddReaderResponse
    >("/AddReader", model);
    loggerService(response, "/AddReader: ");
    return response;
  },

  async notify(
    model: ReaderMessageNotifyRequest
  ): Promise<ReaderMessageNotifyResponse> {
    const response = await networkService().post<
      ReaderMessageNotifyRequest,
      ReaderMessageNotifyResponse
    >("/ReaderMessage", {
      ...model,
      action: "notify",
    });
    loggerService(response, "/ReaderMessage(Notify): ");
    return response;
  },

  async ping(
    model: ReaderMessagePingRequest
  ): Promise<ReaderMessagePingResponse> {
    const response = await networkService().post<
      ReaderMessagePingRequest,
      ReaderMessagePingResponse
    >("/ReaderMessage", { ...model, action: "ping" });
    loggerService(response, "/ReaderMessage(Ping): ");
    return response;
  },

  async sale(
    model: ReaderMessageSaleRequest
  ): Promise<ReaderMessageSaleResponse> {
    const response = await networkService().post<
      ReaderMessageSaleRequest,
      ReaderMessageSaleResponse
    >("/ReaderMessage", {
      ...model,
      action: "sale",
    });
    loggerService(response, "/ReaderMessage(Sale): ");
    return response;
  },
};

// Mock
mock.onPost("/AddReader").reply(() => {
  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

mock.onPost("/ReaderMessage").reply((config) => {
  const data = JSON.parse(config.data);
  const action = data.action;

  if (action === "ping") {
    const sn = data.sn;
    for (let device of databaseService.devices) {
      if (device.reader_sn !== sn) {
        continue;
      }
      device.last_ping = data.timestamp;
      break;
    }
    databaseService.pingActions.push({
      sn: sn,
      timestamp: data.timestamp,
    });
  }

  if (action === "sale") {
    const sn = data.sn;
    for (let device of databaseService.devices) {
      if (device.reader_sn !== sn) {
        continue;
      }
      device.last_action = data.timestamp;
      device.cash_total += data.payment_type === "1" ? +data.amount : 0;
      device.cashless_total += data.payment_type === "2" ? +data.amount : 0;
      device.total += +data.amount;
      break;
    }
    databaseService.saleActions.push({
      sn: sn,
      timestamp: data.timestamp,
      payment_type: data.payment_type,
      amount: +data.amount,
    });
  }

  if (action === "notify") {
    const sn = data.sn;
    for (let device of databaseService.devices) {
      if (device.reader_sn !== sn) {
        continue;
      }

      if (data.value === "0") {
        const index = device.notify.indexOf(data.code);
        if (index > -1) {
          device.notify.splice(index, 1);
        }
        break;
      }

      if (data.value === "1") {
        const value = device.notify.find((value) => value === data.code);
        if (value === undefined) {
          device.notify.push(data.code);
        }
        break;
      }

      break;
    }
    databaseService.notifyActions.push({
      sn: sn,
      timestamp: data.timestamp,
      code: data.code,
      value: data.value,
    });
  }

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

import { observer } from "mobx-react-lite";
import { FC } from "react";
import c from "classnames";

import LocalImage from "src/ui/image/LocalImage";

type Props = {
  className?: string;
}

const Logo: FC<Props> = ({ className }) => {
  return <LocalImage className={c(className)} src="logo.svg" alt="i-Pay" />
};

export default observer(Logo);
import { TFunction } from "react-i18next";
import { string } from "yup";

// Логин
export const loginSchema = (t: TFunction) =>
  string()
    .required(t("common.login_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// Пароль
export const passwordSchema = (t: TFunction) =>
  string()
    .required(t("common.password_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// ФИО
export const fioSchema = (t: TFunction) =>
  string()
    .required(t("common.fio_required"))
    .min(3, t("common.min_3"))
    .max(100, t("common.max_100"));

// Телефон
export const phoneSchema = (t: TFunction) =>
  string()
    .required(t("common.phone_required"))
    .min(3, t("common.min_3"))
    .max(20, t("common.max_20"));

// Почта
export const emailSchema = (t: TFunction) =>
  string()
    .required(t("common.email_required"))
    .email(t("common.email_error"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// Наименование владельца
export const ownerNameSchema = (t: TFunction) =>
  string()
    .required(t("common.owner_name_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// ИНН
export const innSchema = (t: TFunction) =>
  string()
    .required(t("common.inn_required"))
    .matches(/^([0-9]{10}|[0-9]{12})$/, t("common.inn_error"));

// Адрес
export const addressSchema = (t: TFunction) =>
  string()
    .required(t("common.address_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// Наименование устройства
export const deviceNameSchema = ownerNameSchema;

// Серийный номер
export const snSchema = (t: TFunction) =>
  string()
    .required(t("common.sn_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// ПИН
export const pinSchema = (t: TFunction) =>
  string()
    .required(t("common.reader_pin_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

// API Key
export const apikeySchema = (t: TFunction) =>
  string()
    .required(t("common.apikey_required"))
    .min(3, t("common.min_3"))
    .max(30, t("common.max_30"));

import { action, autorun, makeObservable, observable, toJS } from "mobx";
import { createContext, useContext } from "react";

import { loggerService } from "src/services/logger";
import { storageService } from "src/services/storage";
import { constants } from "src/common/constants";

class AuthStore {
  @observable
  isAuthorized = false;

  @observable
  login: string | undefined = undefined;

  @observable
  password: string | undefined = undefined;

  @observable
  lastOwnerId: string | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  get storageType() {
    const isDemo =
      storageService("sessionStorage").get(constants.storage.demoStoreKey) ===
      "true";
    const storageType = isDemo ? "sessionStorage" : "localStorage";
    return storageType;
  }

  @action.bound
  setAuthorizedState(value: boolean) {
    this.isAuthorized = value;
    storageService(this.storageType).add({
      key: constants.storage.isAuthorizedStoreKey,
      value: `${value}`,
    });
  }

  @action.bound
  setCredentials(login: string, password: string) {
    this.login = login;
    this.password = password;
    storageService(this.storageType).add({
      key: constants.storage.loginStoreKey,
      value: login,
    });
    storageService(this.storageType).add({
      key: constants.storage.passwordStoreKey,
      value: password,
    });
  }

  @action.bound
  setLastOwnerId(id: string) {
    this.lastOwnerId = id;
    storageService(this.storageType).add({
      key: constants.storage.ownerIdStoreKey,
      value: id,
    });
  }

  @action.bound
  updatePasword(newPassword: string) {
    this.password = newPassword;
    storageService(this.storageType).remove(constants.storage.passwordStoreKey);
    storageService(this.storageType).add({
      key: constants.storage.passwordStoreKey,
      value: newPassword,
    });
  }

  @action.bound
  clear() {
    this.isAuthorized = false;
    this.login = undefined;
    this.password = undefined;
    this.lastOwnerId = undefined;
    storageService(this.storageType).clear();
  }
}

export const authStore = new AuthStore();

const authProvider = createContext(authStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(authStore), "Auth Store changed:");
  });
}

export const useAuthStore = () => {
  const store = useContext(authProvider);
  return store;
};

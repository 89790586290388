export const databaseService = {
  user: {
    login: "demo",
    name: "Demo",
    registered: 1618434453,
    phone: "+79999999999",
    email: "demo@demo.demo",
    privilege: "0",
  },
  owners: [
    {
      id: "0",
      name: "Demo",
      inn: "7700000000",
      address: "Демо адрес",
      email: "demo@demo.demo",
      phone: "+79999999999",
      registered: "demo",
      timezone: "UTC+3 Москва",
      currency: "руб.",
      language: "Русский",
    },
  ],
  devices: [
    {
      id: 1111,
      name: "Demo device 1",
      sn: "1111",
      address: "Демо адрес 1",
      reader_sn: "1111",
      cash_total: 0,
      cashless_total: 0,
      last_action: null,
      last_ping: null,
      notify: [] as number[],
      total: 0,
    },
    {
      id: 2222,
      name: "Demo device 2",
      sn: "2222",
      address: "Демо адрес 2",
      reader_sn: "2222",
      cash_total: 0,
      cashless_total: 0,
      last_action: null,
      last_ping: null,
      notify: [] as number[],
      total: 0,
    },
    {
      id: 3333,
      name: "Demo device 3",
      sn: "3333",
      address: "Демо адрес 3",
      reader_sn: "3333",
      cash_total: 0,
      cashless_total: 0,
      last_action: null,
      last_ping: null,
      notify: [] as number[],
      total: 0,
    },
  ],
  saleActions: [] as {
    sn: string;
    timestamp: number;
    payment_type: string;
    amount: number;
  }[],
  pingActions: [] as {
    sn: string;
    timestamp: number;
  }[],
  notifyActions: [] as {
    sn: string;
    timestamp: number;
    code: string;
    value: string;
  }[],
};

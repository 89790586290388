import { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useAuthStore } from "src/stores/auth";

const PrivateRoute: FC<RouteProps> = ({ ...routeProps }) => {
  const authStore = useAuthStore();

  if (authStore.isAuthorized) {
    return (
      <Route {...routeProps} />
    );
  }

  return (
    <Redirect to={{
      pathname: "/login"
    }} />
  )
};

export default observer(PrivateRoute);
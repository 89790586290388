import { StorageService, StorageType } from "./types";
import { localStorageService } from "./localStorage";
import { sessionStorageService } from "./sessionStorage";

export const storageService = (
  type: StorageType = "localStorage"
): StorageService => {
  if (type === "localStorage") {
    return localStorageService;
  }
  return sessionStorageService;
};

import { FC } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react-lite";
import { CSSTransition } from "react-transition-group";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  visible: boolean;
  className?: string,
}

const Overlay: FC<Props> = ({ visible, className, children }) => {
  return createPortal(
    <>
      <CSSTransition
        in={visible}
        timeout={600}
        unmountOnExit
        classNames={{
          enter: s.fadeEnter,
          enterActive: s.fadeEnterActive,
          exit: s.fadeExit,
          exitActive: s.fadeExitActive
        }}
      >
        <div className={c(s.overlay, className)}>
          <div className={s.wrap}>
            { children }
          </div>
        </div>
      </CSSTransition>
    </>,
    document.getElementById("overlay") as Element
  );
};

export default observer(Overlay);
import { useEffect, useState } from "react";
import { RangeValue } from "rc-picker/lib/interface";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";

import { withAuth } from "src/utils/withAuth";
import { reportService } from "src/services/report";
import { AlertItem } from "src/stores/alert/types";
import { useAlertStore } from "src/stores/alert";
import { withTryCatch } from "src/utils/withTryCatch";

export const useEventsList = () => {
  const [isLoading, setLoading] = useState(false);
  const [dates, setDates] = useState<RangeValue<Moment>>([
    moment.utc(Date.now()).subtract(1, "M"),
    moment.utc(Date.now()),
  ]);
  const [data, setData] = useState<
    {
      timestamp: number;
      code: number;
      deviceName: string;
      deviceId: number;
    }[]
  >([]);

  const alertStore = useAlertStore();
  const { t } = useTranslation();

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  const onChange = (dates: RangeValue<Moment>) => {
    setDates(dates);
  };

  const update = async () => {
    await withTryCatch(
      t,
      async () => {
        setLoading(true);
        await getEventsByPeriodRequest();
      },
      undefined,
      async () => {
        setLoading(false);
      }
    );
  };

  const getEventsByPeriodRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await reportService.getEventsByPeriod(
        login,
        password,
        dates![0]!.unix(),
        dates![1]!.unix()
      );
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        setData(data.data);
        return;
      }

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };

      errorAlert.description = t("network.unknown_error");
      alertStore.push(errorAlert);
    });
  };

  return {
    isLoading,
    dates,
    data,
    t,
    onChange,
  };
};

import { useFormik } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { object } from "yup";

import { useAlertStore } from "src/stores/alert";
import { useUserStore } from "src/stores/user";
import { AlertItem } from "src/stores/alert/types";
import { useAuthStore } from "src/stores/auth";
import { withAuth } from "src/utils/withAuth";
import { userService } from "src/services/user";
import {
  emailSchema,
  fioSchema,
  loginSchema,
  phoneSchema,
} from "src/validators/";
import { withTryCatch } from "src/utils/withTryCatch";

export const useUpdateUser = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const userStore = useUserStore();
  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      login: userStore.login ?? "",
      fio: userStore.name ?? "",
      phone: userStore.phone ?? "",
      email: userStore.email ?? "",
    },
    validationSchema: object({
      login: loginSchema(t),
      fio: fioSchema(t),
      phone: phoneSchema(t),
      email: emailSchema(t),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await updateUserInfoRequest();

          if (isError.current) {
            return;
          }

          await getUserInfoRequest();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const updateUserInfoRequest = async () => {
    await withAuth(t, async (internalLogin, password) => {
      const response = await userService.update(internalLogin, password, {
        login: formik.values.login,
        name: formik.values.fio,
        email: formik.values.email,
        phone: formik.values.phone,
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        authStore.setCredentials(formik.values.login, password);
        const successAlert: AlertItem = {
          title: t("common.success"),
          type: "success",
        };
        alertStore.push(successAlert);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  const getUserInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await userService.get(login, password);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        userStore.setUser(data.data);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  return {
    isLoading,
    formik,
  };
};

import { FC, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Divider } from "antd";
import c from "classnames";

import { useOwnerStore } from "src/stores/owner";
import { useOwnersStore } from "src/stores/owners";
import { OwnerItem } from "src/stores/owner/types";
import Text from "src/ui/typography/Text";
import LocalImage from "src/ui/image/LocalImage";

import s from "./style.module.sass";
import { useTitle } from "src/hooks/useTitle";

const Company: FC = () => {
  const ownerStore = useOwnerStore();
  const ownersStore = useOwnersStore();

  const owners = (() => {
    return ownersStore.owners.filter(
      (owner) => owner.id !== ownerStore.id
    );
  })();
  
  const currentOwner = (() => {
    return ownersStore.owners.find(
      (owner) => owner.id === ownerStore.id
    );
  })();

  const setOwner = (owner: OwnerItem) => {
    ownerStore.setOwner(owner);
  };

  useTitle("Сменить организацию");

  return (
    <>
      <div className={s.companies}>
        <div className={s.item}>
          <div className={s.name}>
            <Text className={c(s.blue, s.title)} size={15} weight={600}>
              { currentOwner?.name }
            </Text>
            <Text className={s.gray} size={13} weight={600}>
              { "ИНН: " + currentOwner?.inn }
            </Text>
          </div>
        </div>
        { owners.map((owner) =>
          <Fragment key={owner.id}>
            <Divider className={s.divider} />
            <div onClick={() => setOwner(owner)} className={c(s.item, s.active)}>
              <div className={s.name}>
                <Text className={s.title} size={15} weight={600}>
                  { owner.name }
                </Text>
                <Text className={s.gray} size={13} weight={600}>
                  { "ИНН: " + owner.inn }
                </Text>
              </div>
              <LocalImage src="arrow-right.svg" alt="arrow" />
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};

export default observer(Company);

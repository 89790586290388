import { FC } from "react";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router";
import { Divider } from "antd";
import c from "classnames";

import Text from "src/ui/typography/Text";
import LocalImage from "src/ui/image/LocalImage";
import { useOwnerStore } from "src/stores/owner";

import s from "./style.module.sass";
import { constants } from "src/common/constants";

type Props = {
  className?: string;
  onClick?: () => void;
};

const Sidebar: FC<Props> = ({
  className, onClick
}) => {
  const ownerStore = useOwnerStore();
  const history = useHistory();

  const onInnerClick = (to: string) => {
    history.push(to);

    if (onClick) {
      onClick();
    }
  };

  return (
    <aside className={c(s.sidebar, className)}>
      <div className={s.wrap}>
        {/* Change organization */}
        <div className={c(s.row, s.big)}>
          <button onClick={() => onInnerClick("/company")} className={c(s.link, s.hover)}>
            <div className={s.companyInfo}>
              <Text className={s.black} size={15} weight={600}>
                { ownerStore.name }
              </Text>
              <Text className={s.gray} size={13} weight={600}>
                Сменить организацию
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        <Divider className={c(s.divider, s.hidden)} />
        {/* Dashboard */}
        <div className={c(s.row, s.first)}>
          <button onClick={() => onInnerClick("/")} className={c(s.link, s.hover)}>
            <div className={s.item}>
              <LocalImage className={c(s.img, s.mr)} src="dashboard.svg" alt="dashboard" />
              <Text className={c(s.linkText, s.black)} size={13} weight={600}>
                Dashboard
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        <Divider className={c(s.divider, s.hidden)} />
        {/* Profile */}
        <div className={s.row}>
          <button onClick={() => onInnerClick("/profile")} className={c(s.link, s.hover)}>
            <div className={s.item}>
              <LocalImage className={c(s.img, s.mr)} src="profile.svg" alt="profile" />
              <Text className={c(s.linkText, s.black)} size={13} weight={600}>
                Профиль
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        <Divider className={c(s.divider, s.hidden)} />
        {/* Reports */}
        <div className={s.row}>
          <button onClick={() => onInnerClick("/reports")} className={c(s.link, s.hover)}>
            <div className={s.item}>
              <LocalImage className={c(s.img, s.mr)} src="reports.svg" alt="reports" />
              <Text className={c(s.linkText, s.black)} size={13} weight={600}>
                Отчеты
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        <Divider className={c(s.divider, s.hidden)} />
        {/* Devices */}
        <div className={s.row}>
          <button onClick={() => onInnerClick("/devices")} className={c(s.link, s.hover)}>
            <div className={s.item}>
              <LocalImage className={c(s.img, s.mr)} src="devices.svg" alt="devices" />
              <Text className={c(s.linkText, s.black)} size={13} weight={600}>
                Аппараты
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        <Divider className={c(s.divider, s.hidden)} />
        {/* DEBUG */}
        <div className={s.row}>
          <button onClick={() => onInnerClick("/debug")} className={c(s.link, s.hover)}>
            <div className={s.item}>
              <LocalImage className={c(s.img, s.mr)} src="debug.svg" alt="debug" />
              <Text className={c(s.linkText, s.red)} size={13} weight={600}>
                Debug
              </Text>
            </div>
            <LocalImage className={c(s.img, s.hidden)} src="arrow-right.svg" alt="arrow" />
          </button>
        </div>
        {/* Logout */}
        <div className={s.logout}>
          <Divider className={c(s.divider)} />
          <div className={s.row}>
            <button onClick={() => history.push("/logout")} className={c(s.link, s.hover)}>
              <div className={s.item}>
                <LocalImage className={c(s.img, s.mr)} src="logout.svg" alt="logout" />
                <Text className={c(s.linkText)} size={13} weight={600}>
                  Выйти
                </Text>
              </div>
            </button>
          </div>
          <Divider className={c(s.divider)} />
          <div className={s.row}>
            <div className={s.link}>
              <Text className={c(s.gray, s.version)} size={10}>
                { "Версия приложения: " + constants.appVersion }
              </Text>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default observer(Sidebar);
import { action, autorun, makeObservable, observable, toJS } from "mobx";
import { createContext, useContext } from "react";

import { loggerService } from "src/services/logger";
import { OwnerItem } from "src/stores/owner/types";

class OwnersStore {
  @observable
  owners: OwnerItem[] = [];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  set(owners: OwnerItem[]) {
    this.owners = owners;
  }

  @action.bound
  push(owner: OwnerItem) {
    this.owners.push(owner);
  }

  @action.bound
  clear() {
    this.owners = [];
  }
}

export const ownersStore = new OwnersStore();

const ownersProvider = createContext(ownersStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(ownersStore), "Owners Store changed:");
  });
}

export const useOwnersStore = () => {
  const store = useContext(ownersProvider);
  return store;
};

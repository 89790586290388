import AuthLayout from "./AuthLayout";
import AccountLayout from "./AccountLayout";
import DefaultLayout from "./DefaultLayout";

const Layouts = {
  Auth: AuthLayout,
  Account: AccountLayout,
  Default: DefaultLayout,
};

export default Layouts;

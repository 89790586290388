import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import Logo from "src/ui/image/Logo";
import Text from "src/ui/typography/Text";

import { constants } from "src/common/constants";

import s from "./style.module.sass";

const AuthLayout: FC = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const bannerElement = (() => {
    if (location.pathname === "/login") {
      return (
        <>
          <Logo className={s.logo} />
          <Text className={s.title} size={28} weight={700}>
            { t("login_page.welcome") }
          </Text>
          <Link to="/registration">
            <Text className={s.subtitle} weight={600}>
              { t("login_page.is_register") }
            </Text>
          </Link>
        </>
      )
    }
    return null;
  })();

  return (
    <>
      <main className={s.main}>
        <div className={s.wrap}>
          <aside className={s.banner}>
            <div className={s.bannerWrap}>
              { bannerElement }
            </div>
          </aside>
          <section className={s.hero}>
            <header className={s.header}>
              <Logo className={s.headerLogo} />
            </header>
            <div className={s.body}>
              <div className={s.bodyWrap}>
                { children }
              </div>
            </div>
            <footer className={s.footer}>
              <div className={s.footerWrap}>
                <Text size={13} className={s.footerText}>
                  { "i-Pay © " + new Date().getFullYear() + " " + t("common.version") + constants.appVersion }
                </Text>
              </div>
            </footer>
          </section>
        </div>
      </main>
    </>
  );
};

export default observer(AuthLayout);

import moment from "moment";

import { networkService, mock } from "src/services/network";
import { Response } from "src/services/network/types";
import { loggerService } from "src/services/logger";
import { databaseService } from "src/services/database";

export const reportService = {
  async getDevicesCount(
    login: string,
    password: string
  ): Promise<
    Response<{
      online: number;
      offline: number;
      warning: number;
    }>
  > {
    const response = await networkService().post<
      null,
      Response<{
        online: number;
        offline: number;
        warning: number;
      }>
    >("/GetDevicesCount", null, {
      headers: {
        login,
        password,
      },
    });
    loggerService(response, "/GetDevicesCount: ");
    return response;
  },

  async getSalesByPeriod(
    login: string,
    password: string,
    id?: number
  ): Promise<
    Response<{
      today: number;
      yesterday: number;
      week: number;
      month: number;
    }>
  > {
    const response = await networkService().post<
      { device_id: number },
      Response<{
        today: number;
        yesterday: number;
        week: number;
        month: number;
      }>
    >(
      "/GetSalesByPeriod",
      { device_id: id },
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetSalesByPeriod: ");
    return response;
  },

  async getEventsByPeriod(
    login: string,
    password: string,
    startDate: number,
    endDate: number,
    id?: number
  ): Promise<
    Response<
      {
        timestamp: number;
        code: number;
        deviceName: string;
        deviceId: number;
      }[]
    >
  > {
    const response = await networkService().post<
      {
        start_date: number;
        end_date: number;
        device_id: number;
      },
      Response<
        {
          timestamp: number;
          code: number;
          deviceName: string;
          deviceId: number;
        }[]
      >
    >(
      "/GetEventsByPeriod",
      {
        start_date: startDate,
        end_date: endDate,
        device_id: id,
      },
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetEventsByPeriod: ");
    return response;
  },
};

// Mock
mock.onPost("/GetDevicesCount").reply((config) => {
  const data = {
    online: 0,
    offline: 0,
    warning: 0,
  };

  databaseService.devices.forEach((device) => {
    if (device.last_ping === null || device.last_ping === undefined) {
      data.offline += 1;
      return;
    }
    if (device.notify.length > 0) {
      data.warning += 1;
      return;
    }
    data.online += 1;
  });

  return [
    200,
    {
      error: 0,
      description: "OK",
      data,
    },
  ];
});

mock.onPost("/GetSalesByPeriod").reply((config) => {
  const data = JSON.parse(config.data);
  const deviceID = data.device_id as number | undefined;
  const device = databaseService.devices.filter(
    (device) => device.id === deviceID
  )[0];
  const todayTimestamp = moment(Date.now()).utc(true).startOf("day").unix();
  const yesterdayTimestamp = moment(Date.now())
    .utc(true)
    .startOf("day")
    .subtract(1, "day")
    .unix();
  const weekTimestamp = moment(Date.now()).utc(true).startOf("week").unix();
  const monthTimestamp = moment(Date.now()).utc(true).startOf("month").unix();

  const today = databaseService.saleActions
    .filter((value) => {
      if (device === undefined) {
        return true;
      }
      if (value.sn === device.sn) {
        return true;
      }
      return false;
    })
    .filter((value) => value.timestamp >= todayTimestamp)
    .reduce(
      (prev, current) => {
        prev.amount += current.amount;
        return prev;
      },
      { amount: 0 }
    ).amount;

  const yesterday = databaseService.saleActions
    .filter((value) => {
      if (device === undefined) {
        return true;
      }
      if (value.sn === device.sn) {
        return true;
      }
      return false;
    })
    .filter((value) => {
      if (
        value.timestamp < todayTimestamp &&
        value.timestamp >= yesterdayTimestamp
      ) {
        return true;
      }
      return false;
    })
    .reduce(
      (prev, current) => {
        prev.amount += current.amount;
        return prev;
      },
      { amount: 0 }
    ).amount;

  const week = databaseService.saleActions
    .filter((value) => {
      if (device === undefined) {
        return true;
      }
      if (value.sn === device.sn) {
        return true;
      }
      return false;
    })
    .filter((value) => value.timestamp >= weekTimestamp)
    .reduce(
      (prev, current) => {
        prev.amount += current.amount;
        return prev;
      },
      { amount: 0 }
    ).amount;

  const month = databaseService.saleActions
    .filter((value) => {
      if (device === undefined) {
        return true;
      }
      if (value.sn === device.sn) {
        return true;
      }
      return false;
    })
    .filter((value) => value.timestamp >= monthTimestamp)
    .reduce(
      (prev, current) => {
        prev.amount += current.amount;
        return prev;
      },
      { amount: 0 }
    ).amount;

  return [
    200,
    {
      error: 0,
      description: "OK",
      data: {
        today,
        yesterday,
        week: week,
        month: month,
      },
    },
  ];
});

mock.onPost("/GetEventsByPeriod").reply((config) => {
  const data = JSON.parse(config.data);
  const startDate = moment(data.start_date * 1000)
    .startOf("day")
    .unix();
  const endDate = moment(data.end_date * 1000)
    .endOf("day")
    .unix();

  console.debug(startDate, endDate);

  const response = databaseService.notifyActions
    .filter((action) => {
      if (action.timestamp < startDate) {
        return false;
      }
      if (action.timestamp > endDate) {
        return false;
      }
      return true;
    })
    .map((action) => {
      const data = {
        timestamp: action.timestamp,
        code: action.code,
        deviceName: databaseService.devices.filter(
          (device) => device.reader_sn === action.sn
        )[0].name,
        deviceId: databaseService.devices.filter(
          (device) => device.sn === action.sn
        )[0].id,
      };
      return data;
    })
    .sort((first, second) => first.timestamp - second.timestamp);

  return [
    200,
    {
      error: 0,
      description: "OK",
      data: response,
    },
  ];
});

import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";

import InfoContainer from "src/ui/containers/InfoContainer";
import LocalImage from "src/ui/image/LocalImage";
import Text from "src/ui/typography/Text";

import { useDevicesCount } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
};

const DevicesCount: FC<Props> = ({
  className
}) => {
  const { isLoading, devicesCount, update } = useDevicesCount();

  useEffect(() => {
    update();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InfoContainer className={className}>
      {isLoading ?
        <div className={s.wrap}>
          <div></div>
            <LoadingOutlined className={s.loader} />
          <div></div>
        </div>
        :
        <div className={s.wrap}>
          <div className={s.device}>
            <div className={s.head}>
              <LocalImage className={s.icon} src="network.svg" alt="network" />
              <Text size={13} weight={600}>Автоматов онлайн:</Text>
            </div>
            <div className={s.info}>
              <Text size={28} weight={700}>{ devicesCount.online }</Text>
            </div>
          </div>
          <div className={s.device}>
            <div className={s.head}>
              <LocalImage className={s.icon} src="network-error.svg" alt="network error" />
              <Text size={13} weight={600}>Автоматов оффлайн:</Text>
            </div>
            <div className={s.info}>
              <Text size={28} weight={700}>{ devicesCount.offline }</Text>
            </div>
          </div>
          <div className={s.device}>
            <div className={s.head}>
              <LocalImage className={s.icon} src="warning.svg" alt="warning" />
              <Text size={13} weight={600}>Автоматы с ошибками:</Text>
            </div>
            <div className={s.info}>
              <Text size={28} weight={700}>{ devicesCount.warning }</Text>
            </div>
          </div>
        </div>
      }
    </InfoContainer>
  );
};

export default observer(DevicesCount);
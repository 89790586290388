import { FC, useCallback, useRef, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { observer } from "mobx-react-lite";
import c from "classnames";

import Text from "src/ui/typography/Text";
import Search from "src/ui/inputs/Search";
import LocalImage from "src/ui/image/LocalImage";

import { useDevicesList } from "./hook";
import s from "./style.module.sass";

const DevicesList: FC = () => {
  const store = useDevicesList();
  const history = useHistory();
  const intersectionObserver = useRef(
    new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        if (store.page >= store.pageAmount) {
          return;
        }
        if (store.isLoading) {
          return;
        }
        store.setPage(store.page + 1);
      })},
      { threshold: 0.5 }
  ));

  const intersect = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      intersectionObserver.current.observe(node);
    }
  }, []);

  const problemClass = (problem: string | number) => {
    return s[`problem_${problem}`]
  };

  const onClick = (id: number) => {
    history.push(`/device/${id}`);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intersectionObserver.current.disconnect();
    };
  }, [])

  return (
    <div className={s.widget}>
      {/* Title */}
      <div className={s.title}>
        <Text size={16} weight={700}>{ "Всего: " + store.count }</Text>
        { store.isLoading && <LoadingOutlined className={s.loader} /> }
      </div>
      {/* Head */}
      <div className={s.tableHead}>
        <div className={s.search}>
          <Search
            name="search"
            value={store.search}
            placeholder="Поиск"
            onChange={store.onChange}
          />
        </div>
        <div className={s.head}>
          <div className={c(s.item1, s.itemHead)}>
            <Text
              size={13}
              weight={600}
              className={c(s.textWrap)}
            >Наименование</Text>
            <Text
              size={13}
              weight={600}
              className={c(s.textWrap, s.gray)}
            >Серийный номер</Text>
          </div>
          <div className={c(s.itemHead, s.item2)}>
            <Text className={s.textWrap} size={13} weight={600}>Адрес</Text>
          </div>
          <div className={c(s.itemHead, s.item3)}>
            <Text className={s.textWrap} size={13} weight={600}>События</Text>
          </div>
          <div className={c(s.itemHead, s.item4)}>
            <Text className={s.textWrap} size={13} weight={600}>Проблемы</Text>
          </div>
          <div className={c(s.itemHead, s.item5)}>
            <Text className={s.textWrap} size={13} weight={600}>Общая выручка</Text>
          </div>
        </div>
      </div>
      {/* Content */}
      <div className={s.table}>
        <div className={s.content}>
          { store.displayDevices.map((device) =>
            <div key={device.id} className={s.row}>
              <div onClick={() => onClick(device.id)} className={c(s.itemBody, s.item1)}>
                <Text size={13} weight={600} className={c(s.big, s.blue)}>{ device.name }</Text>
                <Text size={13} className={c(s.big, s.gray)}>{ device.sn }</Text>
              </div>
              <div className={c(s.itemBody, s.item2)}>
                <div className={s.nowrap}>
                  <Text size={13} className={s.itemInfo}>Адрес:</Text>
                  <Text size={13}>{ device.address }</Text>
                </div>
              </div>
              <div className={c(s.itemBody, s.item3)}>
                <div className={s.wrap}>
                  <Text size={13} className={s.itemInfo}>События:</Text>
                  <div className={c(s.nowrap, s.mr)}>
                    <LocalImage className={s.icon} src="money-bag.svg" alt="payments" />
                      <Text className={s.textWrap} size={13}>
                        { device.last_action ?
                          store.t("format.howLong", {value: device.last_action}) :
                          "—"
                        }
                      </Text>
                  </div>
                  <div className={c(s.nowrap, s.mr)}>
                    <LocalImage className={s.icon} src="network.svg" alt="ping" />
                    <Text className={s.textWrap} size={13}>
                      { device.last_ping ?
                        store.t("format.howLong", {value: device.last_ping}) :
                        "—"
                      }
                    </Text>
                  </div>
                </div>
              </div>
              <div className={c(s.itemBody, s.item4)}>
                <div className={s.nowrap}>
                  <Text size={13} className={s.itemInfo}>Проблемы:</Text>
                  { device.notify.map((notify) =>
                    <div key={notify} className={c(s.problem, problemClass(notify))}></div>
                  )}
                </div>
              </div>
              <div className={c(s.itemBody, s.item5)}>
                <div className={s.column}>
                  <Text size={13} className={s.total}>{ store.t("format.currency", { value: device.total }) }</Text>
                  <div className={c(s.nowrap, s.totalInfo)}>
                    <LocalImage className={s.icon2} src="card.svg" alt="card" />
                    <Text size={12}>{ store.t("format.currency", { value: device.cashless_total }) }</Text>
                  </div>
                  <div className={c(s.nowrap, s.totalInfo)}>
                    <LocalImage className={s.icon2} src="cash.svg" alt="cash" />
                    <Text size={12}>{ store.t("format.currency", { value: device.cash_total }) }</Text>
                  </div>
                </div>
              </div>
            </div>
          )}
          { store.canLoad &&
            <div ref={intersect} className={c(s.row, s.rowEmpty)}>
              <div className={c(s.itemBody, s.itemBodyEmpty)}>
                <LoadingOutlined className={s.loader} />
              </div>
            </div>
          }
          { store.isEmpty &&
            <div className={c(s.row, s.rowEmpty)}>
              <div className={c(s.itemBody, s.itemBodyEmpty)}>
                <Text className={s.gray} size={14}>Аппараты отсутствуют</Text>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default observer(DevicesList);
import { useFormik } from "formik";
import { useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { object } from "yup";

import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withTryCatch } from "src/utils/withTryCatch";
import { withAuth } from "src/utils/withAuth";
import { deviceService } from "src/services/device";
import { DeviceLocalStoreContext } from "src/components/providers/DeviceProvider";
import {
  addressSchema,
  deviceNameSchema,
  pinSchema,
  snSchema,
} from "src/validators";

export const useUpdateDevice = () => {
  const [isLoading, setLoading] = useState(false);
  const isError = useRef(false);

  const store = useContext(DeviceLocalStoreContext);
  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      name: store?.name ?? "",
      sn: store?.sn ?? "",
      address: store?.address ?? "",
      reader_sn: store?.reader_sn ?? "",
      reader_pin: "",
    },
    validationSchema: object({
      name: deviceNameSchema(t),
      sn: snSchema(t),
      address: addressSchema(t),
      reader_sn: snSchema(t),
      reader_pin: pinSchema(t),
    }),
    onSubmit: async () => {
      await withTryCatch(
        t,
        async () => {
          setLoading(true);
          await updateDeviceInfoRequest();

          if (isError.current) {
            return;
          }

          await getDeviceInfoRequest();
        },
        undefined,
        async () => {
          setLoading(false);
          isError.current = false;
        }
      );
    },
  });

  const updateDeviceInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await deviceService.update(login, password, {
        device_id: store?.id ?? 0,
        device: {
          name: formik.values.name,
          sn: formik.values.sn,
          address: formik.values.address,
          reader_sn: formik.values.reader_sn,
          reader_pin: formik.values.reader_pin,
        },
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        const successAlert: AlertItem = {
          title: t("common.success"),
          type: "success",
        };
        alertStore.push(successAlert);
        return;
      }

      isError.current = true;

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  const getDeviceInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await deviceService.get(
        login,
        password,
        Number(store?.id) ?? 0
      );
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        store?.setDevice({ id: Number(store.id) ?? 0, ...data.data });
        return;
      }

      isError.current = true;

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };
      alertStore.push(errorAlert);
    });
  };

  return {
    isLoading,
    formik,
  };
};

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useLogout } from "src/hooks/useLogout";
import { ownerService } from "src/services/owner";
import { userService } from "src/services/user";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { useAuthStore } from "src/stores/auth";
import { useOwnerStore } from "src/stores/owner";
import { useOwnersStore } from "src/stores/owners";
import { useUserStore } from "src/stores/user";
import { withAuth } from "src/utils/withAuth";
import { withTryCatch } from "src/utils/withTryCatch";

export const useAccountLayout = () => {
  const [isLoading, setLoading] = useState(true);
  const isError = useRef(false);

  const userStore = useUserStore();
  const ownerStore = useOwnerStore();
  const ownersStore = useOwnersStore();
  const authStore = useAuthStore();
  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const logout = useLogout();

  const update = async () => {
    await withTryCatch(
      t,
      async () => {
        setLoading(true);
        await getUserInfoRequest();

        if (isError.current === false) {
          await getOwnersRequest();
        } else {
          return;
        }
      },
      undefined,
      async () => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        isError.current = false;
      }
    );
  };

  const getUserInfoRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await userService.get(login, password);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        userStore.setUser(data.data);
        return;
      }

      isError.current = true;

      if (data.error === -11) {
        logout();
        return;
      }

      const errorAlert: AlertItem = {
        type: "error",
        title: t("common.error"),
        description: t("network.unknown_error"),
      };
      alertStore.push(errorAlert);
    });
  };

  const getOwnersRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await ownerService.getAll(login, password);
      const data = response.data;

      if (data.error === 0 && data.description === "OK" && data.data) {
        const currentOwner =
          data.data.find((owner) => {
            return owner.id === authStore.lastOwnerId;
          }) ?? data.data[0];
        ownerStore.setOwner(currentOwner);
        ownersStore.set(data.data);
        return;
      }

      isError.current = true;

      if (data.error === -11) {
        logout();
        return;
      }

      const error: AlertItem = {
        title: t("common.error"),
        type: "error",
        description: t("network.unknown_error"),
      };
      alertStore.push(error);
    });
  };

  return {
    isLoading,
    update,
  };
};

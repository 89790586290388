import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router-dom";

import PageContainer from "src/ui/containers/PageContainer";
import Router from "src/ui/layout/Router";
import RouterItem from "src/ui/layout/RouterItem";

import User from "./user";
import Company from "./company";

const Profile: FC = () => {
  return (
    <PageContainer
      routes={
        <Router>
          <RouterItem to="/profile" title="Профиль" />
          <RouterItem to="/profile/company" title="Организация" />
        </Router>
      }
    >
      <Switch>
        <Route exact path="/profile" component={User} />
        <Route exact path="/profile/company" component ={Company} />
      </Switch>
    </PageContainer>
  );
};

export default observer(Profile);

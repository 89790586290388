import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import c from "classnames";

import DateRangePicker from "src/ui/inputs/DateRangePicker";
import Text from "src/ui/typography/Text";

import s from "./style.module.sass";
import { useEventsList } from "./hook";

type Props = {
  className?: string;
};


const EventsList: FC<Props> = ({
  className
}) => {
  const { isLoading, dates, data, t, onChange } = useEventsList();

  const isEmpty = (() => {
    return data.length === 0;
  })();

  return (
    <div className={c(s.widget, className)}>
      <div className={s.title}>
        <Text weight={700}>События</Text>
        { isLoading && <LoadingOutlined className={s.loader} /> }
      </div>
      <div className={s.head}>
        <div className={s.date}>
          <DateRangePicker
            value={dates}
            onChange={onChange}
          />
        </div>
        <div className={s.tableHead}>
          <div className={s.item1}>
            <Text size={13} weight={600}>Дата</Text>
          </div>
          <div className={s.item2}>
            <Text size={13} weight={600}>Событие</Text>
          </div>
          <div className={s.item3}>
            <Text size={13} weight={600}>Автомат</Text>
          </div>
        </div>
      </div>
      <div className={s.table}>
        { data.map((item) =>
          <div key={item.timestamp} className={s.row}>
            <div className={s.item1}>
              <Text size={13}>{ t("format.longDay", { value: +item.timestamp * 1000 }) }</Text>
            </div>
            <div className={s.item2}>
              <Text size={13}>{ item.code }</Text>
            </div>
            <div className={s.item3}>
              <Link to={`/device/${item.deviceId}`}>
                <Text className={s.link} size={13} weight={600}>{ item.deviceName }</Text>
              </Link>
            </div>
          </div>
        )}
        { isEmpty &&
          <div className={c(s.row, s.rowEmpty)}>
            <Text className={s.gray} size={14}>Данные отсутствуют</Text>
          </div>
        }
      </div>
    </div>
  );
};

export default observer(EventsList);
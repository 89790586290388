import { FC, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";
import c from "classnames";

import Text from "src/ui/typography/Text";
import InfoContainer from "src/ui/containers/InfoContainer";
import { DeviceLocalStoreContext } from "src/components/providers/DeviceProvider";

import { useDeviceInfo } from "./hook";
import s from "./style.module.sass";
import LocalImage from "src/ui/image/LocalImage";

type Props = {
  className?: string;
};

const DeviceInfo: FC<Props> = ({
  className
}) => {
  const [visible, setVisible] = useState(false);
  const store = useContext(DeviceLocalStoreContext);
  const { store: deviceInfoStore, t, deleteDevice } = useDeviceInfo(Number(store?.id));

  const asyncOk = async () => {
    await deleteDevice();
    if (deviceInfoStore.isError) {
      setVisible(false);
      deviceInfoStore.setError(false);
      return;
    }
  };

  const problemClass = (problem: string | number) => {
    return s[`problem_${problem}`]
  };

  return (
    <>
      <InfoContainer
        className={className}
        title={store?.name ?? ""}
        rightItem={
          <button
            onClick={() => setVisible(true)}
            className={s.delete}
          >
            <Text size={13} weight={700}>Удалить</Text>
          </button>
        }
      >
        <div className={s.wrap}>
          <div className={s.item}>
            <div className={s.nowrap}>
              <Text size={13} className={s.info}>Серийный номер:</Text>
              <Text size={13} className={s.gray}>{ store?.sn }</Text>
            </div>
            <div className={s.nowrap}>
              <Text size={13} className={s.info}>Адрес:</Text>
              <Text size={13} className={s.gray}>{ store?.address }</Text>
            </div>
          </div>
          <div className={s.item}>
            <div className={s.nowrap}>
              <Text size={13} className={s.info}>Проблемы:</Text>
              { store?.notify.map((notify) =>
                <div key={notify} className={c(s.problem, problemClass(notify))}></div>
              )}
            </div>
            <div className={s.nowrap}>
              <Text size={13} className={s.info}>События:</Text>
              <div className={c(s.nowrap, s.mr)}>
                <LocalImage className={s.icon} src="money-bag.svg" alt="payments" />
                <Text size={13} className={s.gray}>
                  { store?.last_action ?
                    t("format.howLong", {value: store?.last_action}) :
                    "—"
                  }
                </Text>
              </div>
              <div className={c(s.nowrap, s.mr)}>
                <LocalImage className={s.icon} src="network.svg" alt="ping" />
                <Text size={13} className={s.gray}>
                  { store?.last_ping ?
                    t("format.howLong", {value: store?.last_ping}) :
                    "—"
                  }
                </Text>
              </div>
            </div>
          </div>
        </div>
      </InfoContainer>
      <Modal
        title="Подтверждение удаления"
        visible={visible}
        closable={false}
        className={s.modal}
        confirmLoading={deviceInfoStore.isLoading}
        onOk={asyncOk}
        onCancel={() => setVisible(false)}
      >
        <div className={s.modalWrap}>
          <WarningFilled className={s.modalIcon} />
          <Text className={s.modalText} size={13}>Вы уверены что хотите удалить аппарат ?</Text>
        </div>
      </Modal>
    </>
  );
};

export default observer(DeviceInfo);
import { random } from "lodash";

import { networkService, mock } from "src/services/network";
import { loggerService } from "src/services/logger";
import { databaseService } from "src/services/database";
import {
  AddDeviceRequest,
  AddDeviceResponse,
  GetDeviceInfoReg,
  GetDeviceInfoRes,
  GetDevicesReq,
  GetDevicesRes,
  IDeviceService,
  UpdateDeviceInfoReg,
  UpdateDeviceInfoRes,
  DeleteDeviceRequest,
  DeleteDeviceResponse,
} from "./types";

export const deviceService: IDeviceService = {
  async get(
    login: string,
    password: string,
    id: number
  ): Promise<GetDeviceInfoRes> {
    const response = await networkService().post<
      GetDeviceInfoReg,
      GetDeviceInfoRes
    >(
      "/GetDeviceInfo",
      { device_id: id },
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetDeviceInfo: ");
    return response;
  },

  async getAll(
    login: string,
    password: string,
    model: GetDevicesReq
  ): Promise<GetDevicesRes> {
    const response = await networkService().post<GetDevicesReq, GetDevicesRes>(
      "/GetDevices",
      model,
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetDevices: ");
    return response;
  },

  async update(
    login: string,
    password: string,
    model: UpdateDeviceInfoReg
  ): Promise<UpdateDeviceInfoRes> {
    const response = await networkService().post<
      UpdateDeviceInfoReg,
      UpdateDeviceInfoRes
    >("/UpdateDeviceInfo", model, {
      headers: {
        login,
        password,
      },
    });
    loggerService(response, "/UpdateDeviceInfo: ");
    return response;
  },

  async add(
    login: string,
    password: string,
    model: AddDeviceRequest
  ): Promise<AddDeviceResponse> {
    const response = await networkService().post<
      AddDeviceRequest,
      AddDeviceResponse
    >("/AddDevice", model, {
      headers: {
        login: login,
        password: password,
      },
    });
    loggerService(response, "/AddDevice: ");
    return response;
  },

  async delete(
    login: string,
    password: string,
    model: DeleteDeviceRequest
  ): Promise<DeleteDeviceResponse> {
    const response = await networkService().post<
      DeleteDeviceRequest,
      DeleteDeviceResponse
    >("/DeleteDevice", model, {
      headers: {
        login,
        password,
      },
    });
    loggerService(response, "/DeleteDevice: ");
    return response;
  },
};

// Mock
mock.onPost("/GetDeviceInfo").reply((config) => {
  const data = JSON.parse(config.data);
  const deviceId = data.device_id;
  const device = databaseService.devices.filter(
    (device) => device.id === deviceId
  )[0];

  if (device === null || device === undefined) {
    return [200, { error: -8, description: "Bad device_id" }];
  }

  return [
    200,
    {
      error: 0,
      description: "OK",
      data: device,
    },
  ];
});

mock.onPost("/GetDevices").reply(() => {
  return [
    200,
    {
      error: 0,
      description: "OK",
      data: databaseService.devices,
      total_lines: databaseService.devices.length,
    },
  ];
});

mock.onPost("/UpdateDeviceInfo").reply((config) => {
  const data = JSON.parse(config.data);
  const deviceId = data.device_id;
  const device = databaseService.devices.filter(
    (device) => device.id === deviceId
  )[0];

  if (device === null || device === undefined) {
    return [200, { error: -8, description: "Bad device_id" }];
  }

  device.name = data.device.name;
  device.address = data.device.address;
  device.sn = data.device.sn;
  device.reader_sn = data.device.reader_sn;

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

mock.onPost("/AddDevice").reply((config) => {
  const data = JSON.parse(config.data);
  const device = data.device;
  device.id = random(9999999);
  device.reader_pin = undefined;
  device.cash_total = 0;
  device.cashless_total = 0;
  device.last_action = null;
  device.last_ping = null;
  device.notify = [];
  device.total = 0;
  databaseService.devices.push(device);

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

mock.onPost("/DeleteDevice").reply((config) => {
  const data = JSON.parse(config.data);
  const deviceId = data.device_id;
  const device = databaseService.devices.filter(
    (device) => device.id === deviceId
  )[0];
  const index = databaseService.devices.indexOf(device);

  if (index === -1) {
    return [
      200,
      {
        error: -8,
        description: "Bad device_id",
      },
    ];
  }

  databaseService.devices.splice(index, 1);

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

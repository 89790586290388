import { useEffect } from "react";

import { useUIStore } from "src/stores/ui";

export const useTitle = (title: string) => {
  const uiStore = useUIStore();

  useEffect(() => {
    uiStore.setPageTitle(title);

    return () => {
      uiStore.setPageTitle(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);
};

import { FC } from "react";
import { observer } from "mobx-react-lite";
import { DatePicker as DatePickerAntd } from "antd";
import { Moment } from "moment";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  className?: string;
  value: Moment | undefined;
  label?: string;
  fixForFixed?: boolean;
  onChange: (value: Moment | null) => void;
};

const DatePicker: FC<Props> = ({
  className, value, label, fixForFixed = true, onChange
}) => {
  return (
    <div className={c(s.container, className)}>
      { label &&
        <label className={c(s.label)}>
          { label }
        </label>
      }
      <DatePickerAntd
        format="DD-MM-YYYY HH:mm"
        showTime
        className={s.input}
        value={value}
        onChange={onChange}
        getPopupContainer={fixForFixed ? (trigger) => trigger.parentElement as HTMLElement : undefined}
      />
    </div>
  );
};

export default observer(DatePicker);
import { FC } from "react";
import { observer } from "mobx-react-lite";

import FormContainer from "src/ui/containers/FormContainer";
import Input from "src/ui/inputs/Input";
import Select from "src/ui/inputs/Select";
import SubmitButton from "src/ui/buttons/SubmitButton";
import DatePicker from "src/ui/inputs/DatePicker";
import { storageService } from "src/services/storage";
import { constants } from "src/common/constants";

import s from "./style.module.sass";
import { useReaderMessage } from "./hook";

type Props = {
  className?: string;
};

const ReaderMessageNotify: FC<Props> = ({
  className
}) => {
  const { isLoading, formik } = useReaderMessage();
  return (
    <FormContainer title="Событие notify" className={className}>
      <form
        onSubmit={(e) => e.preventDefault()}
        className={s.form}
      >
        <Input
          name="sn"
          label="Серийный номер"
          placeholder="Введите серийный номер"
          error={formik.errors.sn}
          value={formik.values.sn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="apikey"
          label="API ключ"
          placeholder="Введите API ключ"
          className={s.input}
          error={formik.errors.apikey}
          value={formik.values.apikey}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Select
          name="value"
          label="Значение события"
          placeholder="Выберите один из вариантов"
          values={[
            { title: 'Активировать событие', value: '1' },
            { title: 'Деактивировать событие', value: '0' },
          ]}
          className={s.input}
          fixForFixed={false}
          error={formik.errors.value}
          value={formik.values.value}
          onChange={(value) => {
            const e = {
              target: {
                value,
                name: "value"
              }
            };
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
        />
        <Input
          name="code"
          label="Код события"
          placeholder="Введите код события"
          className={s.input}
          error={formik.errors.code}
          value={formik.values.code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        { storageService("sessionStorage").get(constants.storage.demoStoreKey) === "true" &&
          <DatePicker
            className={s.input}
            label="Время события"
            value={formik.values.timestamp}
            fixForFixed={false}
            onChange={(date) => {
              const e = {
                target: {
                  name: "timestamp",
                  value: date
                }
              };
              formik.handleChange(e);
            }}
          />
        }
        <SubmitButton
          className={s.button}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          isDisabled={!(formik.isValid && formik.dirty)}
        >Добавить</SubmitButton>
      </form>
    </FormContainer>
  );
};

export default observer(ReaderMessageNotify);
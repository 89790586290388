import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  className?: string;
}

const Header: FC<Props> = ({
  className, children
}) => {
  return (
    <header className={c(s.header, className)}>
      <div className={s.container}>
        { children }
      </div>
    </header>
  );
};

export default observer(Header);
import { FC, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  className?: string;
  routes?: ReactNode
};

const PageContainer: FC<Props> = ({
  className, children, routes
}) => {
  const routeClass = (() => {
    if (routes === undefined) {
      return s.route
    }
    return "";
  })();

  return (
    <>
      { routes !== undefined &&
        routes
      }
      <div className={c(s.container, className, routeClass)}>
        { children }
      </div>
    </>
  );
};

export default observer(PageContainer);
import { runInAction } from "mobx";
import { authStore } from "src/stores/auth";
import { ownerStore } from "src/stores/owner";
import { ownersStore } from "src/stores/owners";

import { userStore } from "src/stores/user";

export const clearStores = () => {
  runInAction(() => {
    userStore.clear();
    ownerStore.clear();
    ownersStore.clear();
    authStore.clear();
  });
};

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import { CSSTransition } from "react-transition-group";
import c from "classnames";

import Header from "src/ui/layout/Header";
import HeaderPopup from "src/ui/layout/HeaderPopup";
import Sidebar from "src/ui/layout/Sidebar";
import Overlay from "src/ui/layout/Overlay";
import BurgerButton from "src/ui/buttons/BurgerButton";
import BubbleLoader from "src/ui/feedback/BubbleLoader";
import Logo from "src/ui/image/Logo";
import PageTitle from "src/ui/layout/PageTitle";
import { useOwnerStore } from "src/stores/owner";
import { useWindowResize } from "src/hooks/useWindowResize";

import { useAccountLayout } from "./hook";
import s from "./style.module.sass";

const AccountLayout: FC = ({ children }) => {
  const [visible, setVisible] = useState(true);
  const { isLoading, update } = useAccountLayout();
  const { width } = useWindowResize();
  const ownerStore = useOwnerStore();

  const layoutClass = (() => {
    if (visible) {
      return s.layoutPadding
    }
    return "";
  })();

  const isPopupVisible = (() => {
    if (width < 768) {
      return false;
    }
    return true;
  })();

  const isLogoVisible = (() => {
    if (width < 768 && visible === false) {
      return false;
    }
    return true;
  })();

  // Sidebar
  useLayoutEffect(() => {
    if (width < 992) {
      setVisible(false);
    }
  }, [])

  // Update
  useEffect(() => {
    update()
    const disposer = reaction(
      () => ownerStore.id,
      async (_, prevValue) => {
        if (prevValue !== undefined) {
          await update();
        }
      }
    );
    return () => {
      disposer();
    }
  }, [])

  const onClick = () => {
    setVisible((value) => !value)
  };

  const close = () => {
    if (width < 992) {
      setVisible(false);
    }
  };

  return (
    <>
      <div className={s.account}>
        <Header className={s.header}>
          <div className={s.logoWrap}>
            { isLogoVisible ?
              <>
                <Logo className={s.logo} />
                <PageTitle key="pageTitle" visible={false} />
              </>
            :
              <PageTitle key="pageTitle" />
            }
          </div>
          <div className={s.wrap}>
            <BurgerButton onClick={onClick} isOpen={visible} />
            { isPopupVisible && <HeaderPopup /> }
          </div>
        </Header>
        <main>
          <CSSTransition
            in={visible}
            timeout={300}
            unmountOnExit
            classNames={{
              enter: s.slideEnter,
              enterActive: s.slideEnterActive,
              exit: s.slideExit,
              exitActive: s.slideExitActive
            }}
          >
            <Sidebar onClick={close} className={s.sidebar} />
          </CSSTransition>
          <div className={c(s.layout, layoutClass)}>
            { isLoading === false && children }
          </div>
        </main>
      </div>
      <Overlay visible={isLoading}>
        <BubbleLoader />
      </Overlay>
    </>
  );
};

export default observer(AccountLayout);
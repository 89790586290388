import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import DeviceInfoWidget from "src/components/widgets/DeviceInfo";
import DevicePie from "src/components/widgets/DevicePie";
import SalesByPeriod from "src/components/widgets/SalesByPeriod";

import s from "./style.module.sass";

const DeviceInfo: FC = () => {
  return (
    <>
      <DeviceInfoWidget />
      <div className={c(s.wrap, s.mt)}>
        <SalesByPeriod byDevice className={c(s.forms)} />
        <DevicePie className={c(s.forms, s.second)} />
      </div>
    </>
  );
};

export default observer(DeviceInfo);
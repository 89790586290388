import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { useUIStore } from "src/stores/ui";
import Text from "src/ui/typography/Text";

import s from "./style.module.sass";

type Props = {
  visible?: boolean;
}

const PageTitle: FC<Props> = ({
  visible = true
}) => {
  const uiStore = useUIStore();

  return (
    <>
      <Helmet>
        <title>{ uiStore.title }</title>
      </Helmet>
      { visible &&
        <Text className={s.title} size={14} weight={700}>{ uiStore.title }</Text>
      }
    </>
  )
};

export default observer(PageTitle);
import { action, makeObservable, observable } from "mobx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { deviceService } from "src/services/device";
import { useAlertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { withAuth } from "src/utils/withAuth";
import { withTryCatch } from "src/utils/withTryCatch";

class DeviceInfoStore {
  id: number;

  @observable isLoading = false;
  @observable isError = false;

  constructor(id: number) {
    this.id = id;
    makeObservable(this);
  }

  @action.bound
  setLoading(value: boolean) {
    this.isLoading = value;
  }

  @action.bound
  setError(value: boolean) {
    this.isError = value;
  }
}

export const useDeviceInfo = (id: number) => {
  const [store] = useState(() => new DeviceInfoStore(id));

  const alertStore = useAlertStore();

  const { t } = useTranslation();
  const history = useHistory();

  const deleteDevice = async () => {
    await withTryCatch(
      t,
      async () => {
        store.setLoading(true);
        await deleteDeviceRequest();

        if (store.isError) {
          return;
        }

        setTimeout(() => {
          store.setLoading(false);
          history.push("/devices");
        }, 1000);
      },
      async () => {
        store.setError(true);
      },
      async () => {
        setTimeout(() => {
          store.setLoading(false);
        }, 1000);
      }
    );
  };

  const deleteDeviceRequest = async () => {
    await withAuth(t, async (login, password) => {
      const response = await deviceService.delete(login, password, {
        device_id: id,
      });
      const data = response.data;

      if (data.error === 0 && data.description === "OK") {
        const successAlert: AlertItem = {
          title: t("common.success"),
          type: "success",
        };
        alertStore.push(successAlert);
        return;
      }

      store.setError(true);

      const errorAlert: AlertItem = {
        title: t("common.error"),
        type: "error",
      };
      errorAlert.description = t("network.unknown_error");
      alertStore.push(errorAlert);
    });
  };

  return {
    store,
    t,
    deleteDevice,
  };
};

import { FC, Fragment, useState } from "react";
import { Divider } from "antd";
import { observer } from "mobx-react-lite";
import { RangeValue } from "rc-picker/lib/interface";
import c from "classnames";
import moment, { Moment } from "moment";

import Text from "src/ui/typography/Text";
import LocalImage from "src/ui/image/LocalImage";
import DateRangePicker from "src/ui/inputs/DateRangePicker";
import Select from "src/ui/inputs/Select";
import { useTranslation } from "react-i18next";

import s from "./style.module.sass";

type Props = {
  className?: string;
}

const data = [
  { timestamp: "1628173217", total: 2, amount: "1000" },
  { timestamp: "1628259617", total: 2, amount: "1000" },
  { timestamp: "1628518817", total: 2, amount: "1000" },
  { timestamp: "1630506017", total: 2, amount: "2300" },
  { timestamp: "1630592417", total: 2, amount: "2300" },
  { timestamp: "1630678817", total: 2, amount: "2300" },
  { timestamp: "1630765217", total: 2, amount: "2300" },
  { timestamp: "1630851617", total: 2, amount: "2300" },
  { timestamp: "1630938017", total: 2, amount: "2300" },
];

const TotalSales: FC<Props> = ({
  className
}) => {

  const [dates, setDates] = useState<RangeValue<Moment>>([
    moment.utc(Date.now()).subtract(1, "M"),
    moment.utc(Date.now())
  ]);
  const { t } = useTranslation();

  const isNewPeriod = (index: number) => {
    if (index === 0) {
      const timestamp = data[0].timestamp;
      const date = new Date(+timestamp * 1000);
      return t("format.month", { value: date });
    }
    const firstTime = data[index - 1].timestamp;
    const currentTime = data[index].timestamp;
    const firstDate = new Date(+firstTime * 1000);
    const currentDate = new Date(+currentTime * 1000);
    const isNewDate = currentDate.getMonth() - firstDate.getMonth() > 0;
    return isNewDate ? t("format.month", { value: currentDate }) : null;
  };

  return (
    <div className={c(s.widget, className)}>
      <div className={s.title}>
        <Text weight={700}>Продажи</Text>
        <LocalImage src="reload.svg" alt="reload" />
      </div>
      <div className={s.head}>
        <div className={s.inputs}>
          <DateRangePicker
            className={s.date}
            value={dates}
            onChange={(dates) => setDates(dates)}
          />
          <Select
            name="periods"
            placeholder="Выберите один из вариантов"
            className={s.select}
            values={[
              { title: "По часам", value: "0" },
              { title: "По дням", value: "1" },
            ]}
            value={""}
            onChange={() => {}}
          />
        </div>
        <div className={s.tableHead}>
          <div className={s.item1}>
            <Text size={13} weight={600}>Дата</Text>
          </div>
          <div className={s.item2}>
            <Text size={13} weight={600}>Объем продаж</Text>
          </div>
          <div className={s.item3}>
            <Text size={13} weight={600}>Сумма продаж</Text>
          </div>
        </div>
      </div>
      <div className={s.table}>
        { data.map((item, index) =>
          <Fragment key={item.timestamp}>
            { isNewPeriod(index) !== null &&
              <>
                <div className={s.period}>
                  <Text size={13} weight={600}>{ isNewPeriod(index) }</Text>
                </div>
                <Divider className={s.divider} />
              </>
            }
            <div className={s.row}>
              <div className={s.item1}>
                <Text size={13}>{ t("format.shortDay", { value: +item.timestamp * 1000 }) }</Text>
              </div>
              <div className={s.item2}>
                <Text size={13}>{ item.total }</Text>
              </div>
              <div className={s.item3}>
                <Text size={13}>{ t("format.currency", { value: item.total }) }</Text>
              </div>
            </div>
            <Divider className={s.divider} />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default observer(TotalSales);
import { storageService } from "src/services/storage";
import { constants } from "src/common/constants";
import { authStore } from "src/stores/auth";
import { runInAction } from "mobx";

export const initFromStorage = () => {
  const isDemo =
    storageService("sessionStorage").get(constants.storage.demoStoreKey) ===
    "true";
  const storageType = isDemo ? "sessionStorage" : "localStorage";
  const isAuthorizedValue = storageService(storageType).get(
    constants.storage.isAuthorizedStoreKey
  );

  let isAuth = false;
  let login: string | null | undefined = null;
  let password: string | null | undefined = null;
  let ownerId: string | null | undefined = null;

  if (isAuthorizedValue) {
    isAuth = Boolean(JSON.parse(isAuthorizedValue));
  } else {
    return;
  }

  if (isAuth === true) {
    login = storageService(storageType).get(constants.storage.loginStoreKey);
    password = storageService(storageType).get(
      constants.storage.passwordStoreKey
    );
    ownerId = storageService(storageType).get(
      constants.storage.ownerIdStoreKey
    );
  } else {
    return;
  }

  if (login && password && ownerId) {
    runInAction(() => {
      authStore.setCredentials(login as string, password as string);
      authStore.setLastOwnerId(ownerId as string);
      authStore.setAuthorizedState(isAuth);
    });
  } else {
    authStore.clear();
    return;
  }
};

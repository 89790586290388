import { createPortal } from "react-dom";
import { Alert as AlertAntd } from "antd";
import { observer } from "mobx-react-lite";
import { FC } from "react";

import { useAlertStore } from "src/stores/alert";

import s from "./style.module.sass";

const Alerts: FC = () => {
  const alerts = useAlertStore();

  return createPortal(
    <div className={s.wrap}>
      { alerts.items.map((item) => 
        <AlertAntd
          key={item.id}
          message={item.title}
          description={item.description}
          type={item.type}
          className={s.alert}
          closable
          showIcon
          onClose={() => {
            alerts.remove(item)
          }}
        />
      )}
    </div>,
  document.getElementById("alerts") as Element)
};

export default observer(Alerts);
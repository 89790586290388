import { action, autorun, makeObservable, observable, toJS } from "mobx";
import { createContext, useContext } from "react";
import { loggerService } from "src/services/logger";

class UIStore {
  @observable title: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action
  setPageTitle(title: string | null) {
    this.title = title;
  }
}

export const uiStore = new UIStore();

const uiProvider = createContext(uiStore);

if (process.env.NODE_ENV !== "production") {
  autorun(() => {
    loggerService(toJS(uiStore), "User Store changed:");
  });
}

export const useUIStore = () => {
  const store = useContext(uiProvider);
  return store;
};
import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  className?: string;
  isOpen?: boolean;
  onClick?: () => void;
};

const BurgerButton: FC<Props> = ({
  className, isOpen = false, onClick
}) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen])

  const lineClass = (line: 1 | 2 | 3) => {
    if (open === false) {
      return "";
    }
    return s[`line${line}`];
  };

  const onInnerClick = () => {
    setOpen((value) => !value);

    if (onClick) {
      onClick();
    }
  }

  return (
    <button onClick={onInnerClick} className={c(className, s.button)}>
      <span className={c(s.lines, lineClass(1))}></span>
      <span className={c(s.lines, lineClass(2))}></span>
      <span className={c(s.lines, lineClass(3))}></span>
    </button>
  );
};

export default observer(BurgerButton);
import { FC } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import Text from "src/ui/typography/Text";
import LoginForm from "src/components/forms/LoginForm";

import s from "./style.module.sass";

const Login: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{ t("login_page.form_title") }</title>
      </Helmet>
      <Text className={s.title} size={28} weight={700}>
        { t("login_page.form_title") }
      </Text>
      <Text className={s.subtitle}>
        { t("login_page.form_subtitle") }
      </Text>
      <LoginForm className={s.form} />
    </>
  );
};

export default observer(Login);

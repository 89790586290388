import { FC, ReactNode } from "react";
import { observer } from "mobx-react-lite";
import { Divider } from "antd";
import c from "classnames";

import Text from "src/ui/typography/Text";

import s from "./style.module.sass";

type Props = {
  className?: string;
  title?: string;
  rightItem?: ReactNode;
};

const InfoContainer: FC<Props> = ({
  className, title, rightItem, children
}) => {
  return (
    <div className={c(s.container, className)}>
      <div className={s.form}>
        { title && rightItem &&
          <div className={s.head}>
            <Text className={s.title} weight={700}>{ title }</Text>
            { rightItem }
          </div> 
        }
        { children }
      </div>
      <Divider className={s.divider} />
    </div>
  );
};

export default observer(InfoContainer);
import { FC, Fragment, useState } from "react";
import { Divider, Popover } from "antd";
import { observer } from "mobx-react-lite";
import c from "classnames";

import { useOwnerStore } from "src/stores/owner";
import { useOwnersStore } from "src/stores/owners";
import Text from "src/ui/typography/Text";
import LocalImage from "src/ui/image/LocalImage";
import { OwnerItem } from "src/stores/owner/types";

import s from "./style.module.sass";
import { useHistory } from "react-router";

const HeaderPopup: FC = () => {
  const [isOpen, setOpen] = useState(false);

  const history = useHistory();
  const ownerStore = useOwnerStore();
  const ownersStore = useOwnersStore();

  const hasOwner = (() => {
    if (ownerStore.name !== undefined) {
      return true;
    }
    return false;
  })();

  const owners = (() => {
    return ownersStore.owners
      .filter((owner) => owner.id !== ownerStore.id)
      .slice(0, 2);
  })();

  const ownersCount = (() => {
    return ownersStore.owners.filter(
      (owner) => owner.id !== ownerStore.id
    ).length;
  })();

  const onClick = () => {
    setOpen((value) => !value)
  };

  const onClickRow = (owner: OwnerItem) => {
    setOpen(false);
    ownerStore.setOwner(owner);
  };

  const onClickStill = () => {
    setOpen(false);
    history.push("/company")
  };

  return (
    <>
      { hasOwner &&
        <Popover
          trigger="click"
          placement="bottomRight"
          overlayClassName={s.popover}
          visible={isOpen}
          onVisibleChange={onClick}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          content={
            <>
              <div className={s.row}>
                <Text className={s.popupTitle} size={13} weight={600}>
                  Сменить организацию:
                </Text>
              </div>
              <Divider className={s.divider} />
              <div className={s.row}>
                <Text className={s.popupOwner} size={13}>
                  { ownerStore.name }
                </Text>
              </div>
              { owners.map((owner) =>
                <Fragment key={owner.id}>
                  <Divider className={s.divider} />
                  <div
                    className={c(s.row, s.rowClick)}
                    onClick={() => onClickRow(owner)}
                  >
                    <Text className={s.popupOwners} size={13}>
                      { owner.name }
                    </Text>
                  </div>
                </Fragment>
              )}
              { ownersCount > 3 &&
                <>
                  <Divider className={s.divider} />
                  <div
                    className={c(s.row, s.rowClick)}
                    onClick={onClickStill}
                  >
                    <Text className={s.popupTitle} size={13} weight={600}>
                      Еще...
                    </Text>
                  </div>
                </>
              }
            </>
          }
        >
          <button className={s.button}>
            <Text className={s.buttonText} size={13} weight={600}>
              { ownerStore.name }
            </Text>
            <LocalImage className={s.buttonImg} src="down_arrow.svg" alt="arrow" />
          </button>
        </Popover>
      }
    </>
  );
};

export default observer(HeaderPopup);
import { TFunction } from "react-i18next";

import { authStore } from "src/stores/auth";
import { alertStore } from "src/stores/alert";
import { AlertItem } from "src/stores/alert/types";
import { logout } from "./logout";

export const withAuth = async <T>(
  t: TFunction,
  callback: (login: string, password: string) => Promise<T>
) => {
  if (authStore.login !== undefined && authStore.password !== undefined) {
    const value = await callback(authStore.login, authStore.password);
    return value;
  }

  const errorAlert: AlertItem = {
    title: t("common.error"),
    type: "error",
    description: t("network.not_auth_error"),
  };
  alertStore.push(errorAlert);
  logout();
  return null;
};

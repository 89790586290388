import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import s from "./style.module.sass";

type Props = {
  color?: "white" | "blue";
  className?: string;
};

const BubbleLoader: FC<Props> = ({
  color = "white", className
}) => {
  const colorClass = (() => {
    if (color === "white") {
      return s.white;
    }
    return s.blue;
  })();

  return (
    <div className={c(s.bubbles, className, colorClass)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default observer(BubbleLoader);
import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Divider } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import FormContainer from "src/ui/containers/FormContainer";
import Text from "src/ui/typography/Text";

import { useSalesByPeriod } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
  byDevice?: boolean;
};

const SalesByPeriod: FC<Props> = ({
  className, byDevice = false
}) => {
  const { t } = useTranslation();
  const { isLoading, data, update } = useSalesByPeriod(byDevice);

  useEffect(() => {
    update();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormContainer className={className} title="Продажи за период">
      { isLoading ?
        <div className={s.wrap}>
          <LoadingOutlined className={s.loader} />
        </div>
        :
        <>
          <div className={s.money}>
            <Text size={13}>Продажи за сегодня:</Text>
            <Text className={s.info} size={28} weight={700}>{ t("format.currency", { value: data.today }) }</Text>
          </div>
          <div className={s.money}>
            <Text size={13}>Продажи за вчера:</Text>
            <Text className={s.info} size={28} weight={700}>{ t("format.currency", { value: data.yesterday }) }</Text>
          </div>
          <Divider className={s.divider} />
          <div className={s.money}>
            <Text size={13}>С начала недели:</Text>
            <Text className={s.info} size={28} weight={700}>{ t("format.currency", { value: data.week }) }</Text>
          </div>
          <Divider className={s.divider} />
          <div className={s.money}>
            <Text size={13}>С начала месяца:</Text>
            <Text className={s.info} size={28} weight={700}>{ t("format.currency", { value: data.month }) }</Text>
          </div>
        </>
      }
    </FormContainer>
  );
};

export default observer(SalesByPeriod);
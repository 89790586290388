import { FC } from "react";
import { observer } from "mobx-react-lite";

import FormContainer from "src/ui/containers/FormContainer";
import Input from "src/ui/inputs/Input";
import SubmitButton from "src/ui/buttons/SubmitButton";

import { useAddReader } from "./hook";
import s from "./style.module.sass";

type Props = {
  className?: string;
};

const AddReader: FC<Props> = ({
  className
}) => {
  const { isLoading, formik } = useAddReader();

  return (
    <FormContainer className={className} title="Добавить ридер">
      <form
        onSubmit={(e) => e.preventDefault()}
        className={s.form}
      >
        <Input
          name="sn"
          label="Серийный номер"
          placeholder="Введите серийный номер"
          error={formik.errors.sn}
          value={formik.values.sn}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="pin"
          label="ПИН код"
          placeholder="Введите ПИН"
          className={s.input}
          error={formik.errors.pin}
          value={formik.values.pin}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Input
          name="apikey"
          label="API ключ"
          placeholder="Введите API ключ"
          className={s.input}
          error={formik.errors.apikey}
          value={formik.values.apikey}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <SubmitButton
          className={s.button}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
          isDisabled={!(formik.isValid && formik.dirty)}
        >Добавить</SubmitButton>
      </form>
    </FormContainer>
  );
};

export default observer(AddReader);
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Chart } from "chart.js";
import c from "classnames";

import FormContainer from "src/ui/containers/FormContainer";
import Text from "src/ui/typography/Text";
import { DeviceLocalStoreContext } from "src/components/providers/DeviceProvider";

import s from "./style.module.sass";

type Props = {
  className?: string;
}

const DevicePie: FC<Props> = ({
  className
}) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const store = useContext(DeviceLocalStoreContext);
  const { t } = useTranslation();

  const total = (() => {
    if (store?.total !== undefined) {
      return store.total;
    }
    return 0;
  })();

  const cash = (() => {
    if (store?.cash_total !== undefined) {
      return store.cash_total;
    }
    return 0;
  })();

  const cashless = (() => {
    if (store?.cashless_total !== undefined) {
      return store.cashless_total;
    }
    return 0;
  })();

  useEffect(() => {
    if (canvas.current !== null && Chart.getChart(canvas.current) === undefined) {
      new Chart(canvas.current, {
        type: "doughnut",
        options: {
          responsive: false
        },
        data: {
          labels: ["Наличные", "Безналичные"],
          datasets: [{
            data: [cash, cashless],
            backgroundColor: ["rgb(94, 116, 136)", "rgb(0, 122, 255)"],
            hoverOffset: 4,
            borderWidth: 0,
          }]
        }
      });
    }

    return () => {
      if (canvas.current !== null) {
        Chart.getChart(canvas.current)?.destroy();
      }
    };
  }, [canvas]);

  return (
    <FormContainer
      title={`Всего: ${t("format.currency", { value: total }) }`}
      className={c(className)}
    >
      { total === 0 ?
        <div className={s.empty}>
          <Text size={13} weight={700}>Данные отсутствуют</Text>
        </div>
        :
        <canvas ref={canvas} className={s.canvas}></canvas>
      }
    </FormContainer>
  );
};

export default observer(DevicePie);
import { networkService, mock } from "src/services/network";
import { loggerService } from "src/services/logger";
import { databaseService } from "src/services/database";
import {
  AddOwnerReq,
  AddOwnerRes,
  GetOwnersRes,
  IOwnerService,
  UpdateOwnerInfoReq,
  UpdateOwnerInfoRes,
} from "./types";

export const ownerService: IOwnerService = {
  async getAll(login: string, password: string): Promise<GetOwnersRes> {
    const response = await networkService().post<null, GetOwnersRes>(
      "/GetOwners",
      null,
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/GetOwners: ");
    return response;
  },

  async add(
    login: string,
    password: string,
    model: AddOwnerReq
  ): Promise<AddOwnerRes> {
    const response = await networkService().post<AddOwnerReq, AddOwnerRes>(
      "/AddOwner",
      model,
      {
        headers: {
          login,
          password,
        },
      }
    );
    loggerService(response, "/AddOwner: ");
    return response;
  },

  async update(
    login: string,
    password: string,
    model: UpdateOwnerInfoReq
  ): Promise<UpdateOwnerInfoRes> {
    const response = await networkService().post<
      UpdateOwnerInfoReq,
      UpdateOwnerInfoRes
    >("/UpdateOwnerInfo", model, {
      headers: {
        login,
        password,
      },
    });
    loggerService(response, "/UpdateOwnerInfo: ");
    return response;
  },
};

// Mock
mock.onPost("/GetOwners").reply(() => {
  return [
    200,
    {
      error: 0,
      description: "OK",
      data: databaseService.owners,
    },
  ];
});

mock.onPost("/AddOwner").reply(() => {
  return [200, { error: 0, description: "OK" }];
});

mock.onPost("/UpdateOwnerInfo").reply((config) => {
  const data = JSON.parse(config.data).owner;
  databaseService.owners[0].name = data.name;
  databaseService.owners[0].inn = data.inn;
  databaseService.owners[0].address = data.address;
  databaseService.owners[0].phone = data.phone;
  databaseService.owners[0].email = data.email;

  return [
    200,
    {
      error: 0,
      description: "OK",
    },
  ];
});

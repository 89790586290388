import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Switch, Route } from "react-router";

import PageContainer from "src/ui/containers/PageContainer";
import Router from "src/ui/layout/Router";
import RouterItem from "src/ui/layout/RouterItem";

import DevicesAll from "./devices";
import DevicesAdd from "./add";

const Devices: FC = () => {
  return (
    <PageContainer
      routes={
        <Router>
          <RouterItem to="/devices" title="Аппараты" />
          <RouterItem to="/devices/add" title="Добавить" />
        </Router>
      }
    >
      <Switch>
        <Route exact path="/devices" component={DevicesAll} />
        <Route exact path="/devices/add" component={DevicesAdd} />
      </Switch>
    </PageContainer>
  );
};

export default observer(Devices);

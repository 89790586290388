import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import Alerts from "src/ui/feedback/Alerts";
import Router from "src/router";

import { useWindowResize } from "./hooks/useWindowResize";
import { windowResizeHandler } from "./utils/windowResizeHandler";

const App: FC = () => {

  useWindowResize(windowResizeHandler);

  return (
    <>
      <Helmet titleTemplate="%s | i-Pay" defaultTitle="i-Pay" />
      <Router />
      <Alerts />
    </>
  );
}

export default observer(App);

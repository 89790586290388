import { FC } from "react";
import { observer } from "mobx-react-lite";
import c from "classnames";

import UpdateUser from "src/components/forms/UpdateUser";
import UpdatePassword from "src/components/forms/UpdatePassword";
import { useTitle } from "src/hooks/useTitle";

import s from "./style.module.sass";

const ProfileUser: FC = () => {
  useTitle("Профиль")
  return (
    <>
      <div className={s.wrap}>
        <UpdateUser className={s.forms} />
        <UpdatePassword className={c(s.forms, s.second)} />
      </div>
    </>
  );
};

export default observer(ProfileUser);